import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntity, getEntityJP, getEntityVN, updateEntity, createEntity, reset } from './jobs.reducer';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { stateFromHTML } from 'draft-js-import-html';
import axios from 'axios';
import { makeSlug } from 'app/shared/util/entity-utils';

export interface IJobsUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const JobsUpdate = (props: IJobsUpdateProps) => {
  const [isNew] = useState(!props.match.params || !props.match.params.id);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [showEmpty, setShowEmpty] = useState(false);
  const [imgUrl, setImgUrl] = useState(null);
  const { jobsEntity, loading, updating } = props;
  const [slug, setSlug] = useState('');

  const handleClose = () => {
    props.history.push('/jobs' + props.location.search);
  };

  const onChangeUploadImg = async (event: any) => {
    const file = event?.target?.files[0];

    const data = new FormData();
    data.append('file', file);

    const result = await axios.post('/api/job/upload-image', data, {});
    const { link } = result.data;
    if (link) {
      setImgUrl(link);
    }
  };

  const onChangeTitle = event => {
    if (isNew) {
      setSlug(() => makeSlug(event.target.value));
    }
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
      setImgUrl(null);
    } else {
      const params = new URLSearchParams(props.location.search);
	  const lang = params.get('lang');
	  if (lang === null) {
		props.getEntity(props.match.params.id);
	  } else if (lang === 'ja') {
		props.getEntityJP(props.match.params.id);
	  } else if (lang === 'vi') {
		props.getEntityVN(props.match.params.id);
	  }
    }
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  useEffect(() => {
    if (jobsEntity?.image) {
      setImgUrl(jobsEntity?.image);
    }
    if (!isNew && jobsEntity?.content) {
      const htmlContent = jobsEntity?.content;
      const convertedState = stateFromHTML(htmlContent);
      const editorValue = EditorState.createWithContent(convertedState);
      setEditorState(editorValue);
    }
  }, [jobsEntity]);

  const saveEntity = (event, errors, values) => {
    values.validFrom = convertDateTimeToServer(values.validFrom);
    values.validThrough = convertDateTimeToServer(values.validThrough);
    values.createdDate = convertDateTimeToServer(values.createdDate);
    values.postedDate = convertDateTimeToServer(values.postedDate);
    values.updatedDate = convertDateTimeToServer(values.updatedDate);

    if (errors.length === 0) {
      const entity = {
        ...jobsEntity,
        ...values,
      };
      if (imgUrl) {
        entity.image = imgUrl;
      }

      const contentVal = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      if (!editorState.getCurrentContent().hasText()) {
        setShowEmpty(true);
        return;
      }
      entity.content = contentVal;

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  const onEditorStateChange = val => {
    setEditorState(val);
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="wataInternalWebApp.jobs.home.createOrEditLabel" data-cy="JobsCreateUpdateHeading">
            <Translate contentKey="wataInternalWebApp.jobs.home.createOrEditLabel">Create or edit a Jobs</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : jobsEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="jobs-id">
                    <Translate contentKey="global.field.id">ID</Translate>
                  </Label>
                  <AvInput id="jobs-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="titleLabel" for="jobs-title">
                  <Translate contentKey="wataInternalWebApp.jobs.title">Title</Translate>
                </Label>
                <span className="required"> *</span>
                <AvField
                  id="jobs-title"
                  data-cy="title"
                  type="text"
                  name="title"
                  onChange={onChangeTitle}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: translate('entity.validation.required'),
                    },
                    maxLength: { value: 100, errorMessage: translate('entity.validation.max') },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="titleLabel" for="jobs-title-tag">
                  <Translate contentKey="wataInternalWebApp.jobs.titleTag">Title Tag</Translate>
                </Label>
                <span className="required"> *</span>
                <AvField
                  id="jobs-title-tag"
                  data-cy="titleTag"
                  type="text"
                  name="titleTag"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: translate('entity.validation.required'),
                    },
                    maxLength: { value: 100, errorMessage: translate('entity.validation.max') },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="slugLabel" for="jobs-slug">
                  <Translate contentKey="wataInternalWebApp.jobs.slug">Slug</Translate>
                </Label>
                <AvField id="jobs-slug" data-cy="slug" type="text" name="slug" value={slug} disabled={!isNew} />
              </AvGroup>
              <AvGroup>
                <Label id="descriptionLabel" for="jobs-description">
                  <Translate contentKey="wataInternalWebApp.jobs.description">Description</Translate>
                </Label>
                <AvField id="jobs-description" data-cy="description" type="textarea" name="description" />
              </AvGroup>
              <AvGroup hidden={false}>
                <Label id="imageLabel" for="jobs-image">
                  <Translate contentKey="wataInternalWebApp.jobs.image">Image</Translate>
                </Label>
                <br />
                <input id="jobs-image" data-cy="image" type="file" onChange={onChangeUploadImg} name="image" hidden />
                <label
                  htmlFor="jobs-image"
                  style={{
                    background: 'indigo',
                    color: 'white',
                    padding: '0.5rem',
                    borderRadius: '0.3rem',
                  }}
                >
                  Choose File
                </label>
                <span style={{ marginLeft: '1rem' }}>{imgUrl}</span>
              </AvGroup>
              <div>
                <Label id="contentLabel" for="jobs-content">
                  <Translate contentKey="wataInternalWebApp.jobs.content">Content</Translate>
                </Label>
                <span className="required"> *</span>
                <Editor
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                  editorStyle={{
                    border: '1px solid #F1F1F1',
                    minHeight: '100px',
                  }}
                />

                {
                  <div className="invalid-feedback" hidden={!showEmpty}>
                    <Translate contentKey="entity.validation.required"></Translate>
                  </div>
                }
              </div>
              <AvGroup>
                <Label id="validFromLabel" for="jobs-validFrom">
                  <Translate contentKey="wataInternalWebApp.jobs.validFrom">Valid From</Translate>
                </Label>
                <span className="required">*</span>
                <AvInput
                  id="jobs-validFrom"
                  data-cy="validFrom"
                  type="datetime-local"
                  className="form-control"
                  name="validFrom"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.jobsEntity.validFrom)}
                />
              </AvGroup>
              <AvGroup>
                <Label id="validThroughLabel" for="jobs-validThrough">
                  <Translate contentKey="wataInternalWebApp.jobs.validThrough">Valid Through</Translate>
                </Label>
                <AvInput
                  id="jobs-validThrough"
                  data-cy="validThrough"
                  type="datetime-local"
                  className="form-control"
                  name="validThrough"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.jobsEntity.validThrough)}
                />
              </AvGroup>
              <AvGroup>
                <Label id="statusLabel" for="jobs-status">
                  <Translate contentKey="wataInternalWebApp.jobs.status">Status</Translate>
                </Label>
                <AvInput
                  id="jobs-status"
                  data-cy="status"
                  type="select"
                  className="form-control"
                  name="status"
                  value={(!isNew && jobsEntity.status) || 'DRAFT'}
                >
                  <option value="DRAFT">{translate('wataInternalWebApp.JobStatus.DRAFT')}</option>
                  <option value="TO_BE_APPROVED">{translate('wataInternalWebApp.JobStatus.TO_BE_APPROVED')}</option>
                  <option value="PUBLISHED">{translate('wataInternalWebApp.JobStatus.PUBLISHED')}</option>
                  <option value="TRASHED">{translate('wataInternalWebApp.JobStatus.TRASHED')}</option>
                </AvInput>
              </AvGroup>
              <AvGroup hidden={true}>
                <Label id="createdByLabel" for="jobs-createdBy">
                  <Translate contentKey="wataInternalWebApp.jobs.createdBy">Created By</Translate>
                </Label>
                <AvField id="jobs-createdBy" data-cy="createdBy" type="string" className="form-control" name="createdBy" />
              </AvGroup>
              <AvGroup hidden={true}>
                <Label id="createdDateLabel" for="jobs-createdDate">
                  <Translate contentKey="wataInternalWebApp.jobs.createdDate">Created Date</Translate>
                </Label>
                <AvInput
                  id="jobs-createdDate"
                  data-cy="createdDate"
                  type="datetime-local"
                  className="form-control"
                  name="createdDate"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.jobsEntity.createdDate)}
                />
              </AvGroup>
              <AvGroup hidden={true}>
                <Label id="postedDateLabel" for="jobs-postedDate">
                  <Translate contentKey="wataInternalWebApp.jobs.postedDate">Posted Date</Translate>
                </Label>
                <AvInput
                  id="jobs-postedDate"
                  data-cy="postedDate"
                  type="datetime-local"
                  className="form-control"
                  name="postedDate"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.jobsEntity.postedDate)}
                />
              </AvGroup>
              <AvGroup hidden={true}>
                <Label id="updatedDateLabel" for="jobs-updatedDate">
                  <Translate contentKey="wataInternalWebApp.jobs.updatedDate">Updated Date</Translate>
                </Label>
                <AvInput
                  id="jobs-updatedDate"
                  data-cy="updatedDate"
                  type="datetime-local"
                  className="form-control"
                  name="updatedDate"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.jobsEntity.updatedDate)}
                />
              </AvGroup>
              <AvGroup hidden={true}>
                <Label id="updatedByLabel" for="jobs-updatedBy">
                  <Translate contentKey="wataInternalWebApp.jobs.updatedBy">Updated By</Translate>
                </Label>
                <AvField id="jobs-updatedBy" data-cy="updatedBy" type="string" className="form-control" name="updatedBy" />
              </AvGroup>
              <AvGroup check>
                <Label check>
                  <AvInput type="checkbox" name="isNew" /> Urgent
                </Label>
              </AvGroup>
              <br></br>
              <Button tag={Link} id="cancel-save" to="/jobs" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  jobsEntity: storeState.jobs.entity,
  loading: storeState.jobs.loading,
  updating: storeState.jobs.updating,
  updateSuccess: storeState.jobs.updateSuccess,
});

const mapDispatchToProps = {
  getEntity,
  getEntityJP,
  getEntityVN,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(JobsUpdate);
