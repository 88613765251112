import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import axios from 'axios';
import { toast } from 'react-toastify';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { stateFromHTML } from 'draft-js-import-html';

export const JobsDetail = (props: any) => {
  const [dataDetail, setDataDetail] = useState({
    id: null,
    title: null,
    slug: null,
    image: null,
    content: null,
    description: null,
    validFrom: null,
    validThrough: null,
    status: null,
    createdBy: null,
    createdDate: null,
    postedDate: null,
    updatedDate: null,
    updatedBy: null,
    titleTag: null,
  });

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const apiUrl = 'api/job';

  const getEntity = async (id: any) => {
    const requestUrl = `${apiUrl}/?id=${id}&lang=`;
    const result = await axios.get<any>(requestUrl);
    if (result) {
      toast.success('Success!');
    }
    setDataDetail(result?.data);
  };

  useEffect(() => {
    getEntity(props.match.params.id);
  }, []);

  useEffect(() => {
    if (dataDetail?.content) {
      const htmlContent = dataDetail?.content;
      const convertedState = stateFromHTML(htmlContent);
      const editorValue = EditorState.createWithContent(convertedState);
      setEditorState(editorValue);
    }
  }, [dataDetail]);

  return (
    <Row>
      <Col md="8">
        <h2 data-cy="jobsDetailsHeading">
          <Translate contentKey="wataInternalWebApp.jobs.detail.title">Jobs</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{dataDetail.id}</dd>
          <dt>
            <span id="title">
              <Translate contentKey="wataInternalWebApp.jobs.title">Title</Translate>
            </span>
          </dt>
          <dd>{dataDetail.title}</dd>
          <dt>
            <span id="title">
              <Translate contentKey="wataInternalWebApp.jobs.titleTag">Title Tag</Translate>
            </span>
          </dt>
          <dd>{dataDetail.titleTag}</dd>
          <dt>
            <span id="slug">
              <Translate contentKey="wataInternalWebApp.jobs.slug">Slug</Translate>
            </span>
          </dt>
          <dd>{dataDetail.slug}</dd>
          <dt hidden={true}>
            <span id="image">
              <Translate contentKey="wataInternalWebApp.jobs.image">Image</Translate>
            </span>
          </dt>
          <dd hidden={true}>{dataDetail.image}</dd>
          <dt>
            <span id="content">
              <Translate contentKey="wataInternalWebApp.jobs.content">Content</Translate>
            </span>
          </dt>
          <dd>{dataDetail.content}</dd>
          <dt>
            <span id="content">
              <Translate contentKey="wataInternalWebApp.jobs.description">Description</Translate>
            </span>
          </dt>
          <dd>{dataDetail.description}</dd>
          <dt>
            <span id="validFrom">
              <Translate contentKey="wataInternalWebApp.jobs.validFrom">Valid From</Translate>
            </span>
          </dt>
          <dd>{dataDetail.validFrom ? <TextFormat value={dataDetail.validFrom} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="validThrough">
              <Translate contentKey="wataInternalWebApp.jobs.validThrough">Valid Through</Translate>
            </span>
          </dt>
          <dd>{dataDetail.validThrough ? <TextFormat value={dataDetail.validThrough} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="wataInternalWebApp.jobs.status">Status</Translate>
            </span>
          </dt>
          <dd>{dataDetail.status}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="wataInternalWebApp.jobs.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{dataDetail.createdBy}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="wataInternalWebApp.jobs.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>{dataDetail.createdDate ? <TextFormat value={dataDetail.createdDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="postedDate">
              <Translate contentKey="wataInternalWebApp.jobs.postedDate">Posted Date</Translate>
            </span>
          </dt>
          <dd>{dataDetail.postedDate ? <TextFormat value={dataDetail.postedDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="updatedDate">
              <Translate contentKey="wataInternalWebApp.jobs.updatedDate">Updated Date</Translate>
            </span>
          </dt>
          <dd>{dataDetail.updatedDate ? <TextFormat value={dataDetail.updatedDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="updatedBy">
              <Translate contentKey="wataInternalWebApp.jobs.updatedBy">Updated By</Translate>
            </span>
          </dt>
          <dd>{dataDetail.updatedBy}</dd>
        </dl>
        <Button tag={Link} to="/jobs" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/jobs/${dataDetail.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default JobsDetail;
