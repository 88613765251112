import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getAllEntities as getArticleCategories } from 'app/entities/article-categories/article-categories.reducer';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { getEntity, updateEntity, createEntity, reset } from './articles.reducer';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { makeSlug } from 'app/shared/util/entity-utils';
import axios from 'axios';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { stateFromHTML } from 'draft-js-import-html';
import { toast } from 'react-toastify';

export interface IArticlesUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ArticlesUpdate = (props: IArticlesUpdateProps) => {
  const [showEmpty, setShowEmpty] = useState(false);
  const [showEmptyContentImg, setShowEmptyContentImg] = useState(false);
  const [isNew] = useState(!props.match.params || !props.match.params.id);
  const [thumbUrl, setThumbUrl] = useState(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [slug, setSlug] = useState('');

  const { articlesEntity, articleCategories, users, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/articles' + props.location.search);
  };
  const onChangeUploadThumbnail = async (event: any) => {
    const file = event?.target?.files[0];

    const data = new FormData();
    data.append('file', file);

    const result = await axios.post('/api/article/upload-image', data, {});
    const { link } = result.data;
    if (link) {
      setThumbUrl(link);
      toast.success('Upload image success!');
    }
  };

  const onChangeTitle = event => {
    if (isNew) {
      setSlug(() => makeSlug(event.target.value));
    }
  };

  useEffect(() => {
    if (articlesEntity?.thumbnail) {
      setThumbUrl(articlesEntity?.thumbnail);
    }
  }, [articlesEntity]);

  useEffect(() => {
    if (isNew) {
      props.reset();
      setThumbUrl(null);
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getArticleCategories();
    props.getUsers();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  useEffect(() => {
    if (!isNew && articlesEntity?.content) {
      const htmlContent = articlesEntity?.content;
      const convertedState = stateFromHTML(htmlContent);
      const editorValue = EditorState.createWithContent(convertedState);
      setEditorState(editorValue);
    }
  }, [articlesEntity]);

  const saveEntity = (event, errors, values) => {
    values.dateCreated = convertDateTimeToServer(values.dateCreated);
    values.publishedDate = convertDateTimeToServer(values.publishedDate);
    values.updatedDate = convertDateTimeToServer(values.updatedDate);

    if (errors.length === 0) {
      const cate = articleCategories.find(it => it?.id.toString() === values?.categoryId.toString());
      cate.createdDate = new Date(cate.createdDate).toISOString();

      const entity = {
        ...articlesEntity,
        ...values,
        category: cate,
        // poster: users.find(it => it?.id.toString() === values?.posterId.toString()),
        // editor: users.find(it => it?.id.toString() === values?.editorId.toString()),
        author: { id: 1, name: values?.author.name.toString() },
      };
      if (!editorState.getCurrentContent().hasText()) {
        setShowEmpty(true);
      }

      if (thumbUrl) {
        entity.thumbnail = thumbUrl;
      } else {
        setShowEmptyContentImg(true);
      }

      if (!thumbUrl || !editorState.getCurrentContent().hasText()) {
        return;
      }

      const contentVal = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      entity.content = contentVal;

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
    EditorState.createWithContent('');
  };

  const onEditorStateChange = val => {
    setEditorState(val);
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="wataInternalWebApp.articles.home.createOrEditLabel" data-cy="ArticlesCreateUpdateHeading">
            <Translate contentKey="wataInternalWebApp.articles.home.createOrEditLabel">Create or edit a Articles</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : articlesEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="articles-id">
                    <Translate contentKey="global.field.id">ID</Translate>
                  </Label>
                  <AvInput id="articles-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="titleLabel" for="articles-title">
                  <Translate contentKey="wataInternalWebApp.articles.title">Title</Translate>
                </Label>
                <span className="required"> *</span>
                <AvField
                  id="articles-title"
                  data-cy="title"
                  type="text"
                  name="title"
                  onChange={onChangeTitle}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: translate('entity.validation.required'),
                    },
                    maxLength: { value: 100, errorMessage: translate('entity.validation.max') },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="titleTagLabel" for="articles-title-tag">
                  <Translate contentKey="wataInternalWebApp.articles.titleTag">Title</Translate>
                </Label>
                <span className="required"> *</span>
                <AvField
                  id="articles-title-tag"
                  data-cy="titleTag"
                  type="text"
                  name="titleTag"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: translate('entity.validation.required'),
                    },
                    maxLength: { value: 100, errorMessage: translate('entity.validation.max') },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="descLabel" for="articles-description">
                  <Translate contentKey="wataInternalWebApp.articles.description">Meta Description</Translate>
                </Label>
                <AvField id="articles-description" data-cy="description" type="textarea" name="description" />
              </AvGroup>
              <AvGroup>
                <Label id="slugLabel" for="articles-slug">
                  <Translate contentKey="wataInternalWebApp.articles.slug">Slug</Translate>
                </Label>
                <AvField id="articles-slug" data-cy="slug" type="text" name="slug" value={slug} disabled={!isNew} />
              </AvGroup>
              <AvGroup>
                <Label id="thumbnailLabel" for="articles-thumbnail">
                  <Translate contentKey="wataInternalWebApp.articles.thumbnail">Content Image</Translate>
                </Label>
                <span className="required"> *</span>
                <br />
                <input id="works-thumbnail" data-cy="thumbnail" name="thumbnail" type="file" onChange={onChangeUploadThumbnail} hidden />
                <label
                  htmlFor="works-thumbnail"
                  style={{
                    background: 'indigo',
                    color: 'white',
                    padding: '0.5rem',
                    borderRadius: '0.3rem',
                  }}
                >
                  Choose File
                </label>
                {
                  <div className="invalid-feedback" style={{ marginLeft: '1rem' }} hidden={!showEmptyContentImg || thumbUrl}>
                    <Translate contentKey="entity.validation.required"></Translate>
                  </div>
                }
                <span style={{ marginLeft: '1rem' }}>{thumbUrl}</span>
              </AvGroup>
              <AvGroup>
                <Label id="contentAltLabel" for="articles-content-alt">
                  <Translate contentKey="wataInternalWebApp.articles.contentAlt">Content Image Alt</Translate>
                </Label>
                <AvField id="articles-content-alt" data-cy="contentAlt" type="text" name="contentAlt" />
              </AvGroup>
              <AvGroup>
                <Label id="excerptLabel" for="articles-excerpt">
                  <Translate contentKey="wataInternalWebApp.articles.excerpt">Excerpt</Translate>
                </Label>
                <AvField id="articles-excerpt" data-cy="excerpt" type="text" name="excerpt" />
              </AvGroup>
              <div>
                <Label id="contentLabel" for="articles-content">
                  <Translate contentKey="wataInternalWebApp.articles.content">Content</Translate>
                </Label>
                <span className="required"> *</span>
                {/* <AvField
                  id="articles-content"
                  data-cy="content"
                  type="text"
                  name="content"
                /> */}
                <Editor
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                  editorStyle={{
                    border: '1px solid #F1F1F1',
                    minHeight: '100px',
                  }}
                />
                {
                  <div className="invalid-feedback" hidden={!showEmpty}>
                    <Translate contentKey="entity.validation.required"></Translate>
                  </div>
                }
              </div>
              <AvGroup>
                <Label id="statusLabel" for="articles-status">
                  <Translate contentKey="wataInternalWebApp.articles.status">Status</Translate>
                </Label>
                <AvInput
                  id="articles-status"
                  data-cy="status"
                  type="select"
                  className="form-control"
                  name="status"
                  value={(!isNew && articlesEntity.status) || 'DRAFT'}
                >
                  <option value="DRAFT">{translate('wataInternalWebApp.ArticleStatus.DRAFT')}</option>
                  <option value="TO_BE_APPROVED">{translate('wataInternalWebApp.ArticleStatus.TO_BE_APPROVED')}</option>
                  <option value="PUBLISHED">{translate('wataInternalWebApp.ArticleStatus.PUBLISHED')}</option>
                  <option value="TRASHED">{translate('wataInternalWebApp.ArticleStatus.TRASHED')}</option>
                </AvInput>
              </AvGroup>
              <AvGroup hidden={true}>
                <Label id="dateCreatedLabel" for="articles-dateCreated">
                  <Translate contentKey="wataInternalWebApp.articles.dateCreated">Date Created</Translate>
                </Label>
                <AvInput
                  id="articles-dateCreated"
                  data-cy="dateCreated"
                  type="datetime-local"
                  className="form-control"
                  name="dateCreated"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.articlesEntity.dateCreated)}
                />
              </AvGroup>
              <AvGroup hidden={true}>
                <Label id="publishedDateLabel" for="articles-publishedDate">
                  <Translate contentKey="wataInternalWebApp.articles.publishedDate">Published Date</Translate>
                </Label>
                <AvInput
                  id="articles-publishedDate"
                  data-cy="publishedDate"
                  type="datetime-local"
                  className="form-control"
                  name="publishedDate"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.articlesEntity.publishedDate)}
                />
              </AvGroup>
              <AvGroup hidden={true}>
                <Label id="updatedDateLabel" for="articles-updatedDate">
                  <Translate contentKey="wataInternalWebApp.articles.updatedDate">Updated Date</Translate>
                </Label>
                <AvInput
                  id="articles-updatedDate"
                  data-cy="updatedDate"
                  type="datetime-local"
                  className="form-control"
                  name="updatedDate"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.articlesEntity.updatedDate)}
                />
              </AvGroup>
              <AvGroup hidden={true}>
                <Label id="numberOfViewLabel" for="articles-numberOfView">
                  <Translate contentKey="wataInternalWebApp.articles.numberOfView">Number Of View</Translate>
                </Label>
                <AvField id="articles-numberOfView" data-cy="numberOfView" type="string" className="form-control" name="numberOfView" />
              </AvGroup>
              <AvGroup>
                <Label id="authorLabel" for="articles-author">
                  <Translate contentKey="wataInternalWebApp.articles.author">Author</Translate>
                </Label>
                <AvField id="articles-author" data-cy="author" type="text" name="author.name" />
              </AvGroup>
              <AvGroup>
                <Label for="articles-category">
                  <Translate contentKey="wataInternalWebApp.articles.category">Category</Translate>
                </Label>
                <span className="required"> *</span>
                <AvField
                  id="articles-category"
                  data-cy="category"
                  type="select"
                  className="form-control"
                  name="categoryId"
                  value={articlesEntity?.category?.id}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: translate('entity.validation.required'),
                    },
                  }}
                >
                  <option value="" key="0" />
                  {articleCategories
                    ? articleCategories.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.name}
                        </option>
                      ))
                    : null}
                </AvField>
              </AvGroup>
              <AvGroup hidden={true}>
                <Label for="articles-poster">
                  <Translate contentKey="wataInternalWebApp.articles.poster">Poster</Translate>
                </Label>
                <AvInput
                  id="articles-poster"
                  data-cy="poster"
                  type="select"
                  className="form-control"
                  name="posterId"
                  value={articlesEntity?.poster?.id}
                >
                  <option value="" key="0" />
                  {users
                    ? users.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.name}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <AvGroup hidden={true}>
                <Label for="articles-editor">
                  <Translate contentKey="wataInternalWebApp.articles.editor">Editor</Translate>
                </Label>
                <AvInput
                  id="articles-editor"
                  data-cy="editor"
                  type="select"
                  className="form-control"
                  name="editorId"
                  value={articlesEntity?.editor?.id}
                >
                  <option value="" key="0" />
                  {users
                    ? users.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.name}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <AvGroup check>
                <Label check>
                  <AvInput type="checkbox" name="isTableContent" />
                  <Translate contentKey="wataInternalWebApp.articles.isTableContent"> Table Content </Translate>
                </Label>
              </AvGroup>
              <br></br>
              <Button tag={Link} id="cancel-save" to="/articles" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  articleCategories: storeState.articleCategories.entities,
  users: storeState.userManagement.users,
  articlesEntity: storeState.articles.entity,
  loading: storeState.articles.loading,
  updating: storeState.articles.updating,
  updateSuccess: storeState.articles.updateSuccess,
});

const mapDispatchToProps = {
  getArticleCategories,
  getUsers,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ArticlesUpdate);
