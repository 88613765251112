import {combineReducers} from 'redux';
import {loadingBarReducer as loadingBar} from 'react-redux-loading-bar';

import locale, {LocaleState} from './locale';
import authentication, {AuthenticationState} from './authentication';
import applicationProfile, {ApplicationProfileState} from './application-profile';

import administration, {AdministrationState} from 'app/modules/administration/administration.reducer';
import userManagement, {UserManagementState} from 'app/modules/administration/user-management/user-management.reducer';
import register, {RegisterState} from 'app/modules/account/register/register.reducer';
import activate, {ActivateState} from 'app/modules/account/activate/activate.reducer';
import password, {PasswordState} from 'app/modules/account/password/password.reducer';
import settings, {SettingsState} from 'app/modules/account/settings/settings.reducer';
import passwordReset, {PasswordResetState} from 'app/modules/account/password-reset/password-reset.reducer';

// prettier-ignore
import page, {PageState} from 'app/entities/page/page.reducer';
// prettier-ignore
import section, {SectionState} from 'app/entities/section/section.reducer';
// prettier-ignore
import item, {ItemState} from 'app/entities/item/item.reducer';
// prettier-ignore
import works, {WorksState} from 'app/entities/works/works.reducer';
// prettier-ignore
import workTags, {WorkTagsState} from 'app/entities/work-tags/work-tags.reducer';
// prettier-ignore
import jobs, {JobsState} from 'app/entities/jobs/jobs.reducer';
// prettier-ignore
import application, {ApplicationState} from 'app/entities/application/application.reducer';
// prettier-ignore
import articles, {ArticlesState} from 'app/entities/articles/articles.reducer';
// prettier-ignore
import articleCategories, {ArticleCategoriesState} from 'app/entities/article-categories/article-categories.reducer';
// prettier-ignore
import articleTags, {ArticleTagsState} from 'app/entities/article-tags/article-tags.reducer';
// prettier-ignore
import language, {LanguageState} from 'app/entities/language/language.reducer';
// prettier-ignore
import news, {NewsState} from 'app/entities/news/news.reducer';

/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly locale: LocaleState;
  readonly applicationProfile: ApplicationProfileState;
  readonly administration: AdministrationState;
  readonly userManagement: UserManagementState;
  readonly register: RegisterState;
  readonly activate: ActivateState;
  readonly passwordReset: PasswordResetState;
  readonly password: PasswordState;
  readonly settings: SettingsState;
  readonly page: PageState;
  readonly section: SectionState;
  readonly item: ItemState;
  readonly works: WorksState;
  readonly workTags: WorkTagsState;
  readonly jobs: JobsState;
  readonly application: ApplicationState;
  readonly articles: ArticlesState;
  readonly articleCategories: ArticleCategoriesState;
  readonly articleTags: ArticleTagsState;
  readonly language: LanguageState;
  readonly news: NewsState;
  /* jhipster-needle-add-reducer-type - JHipster will add reducer type here */
  readonly loadingBar: any;
}

const rootReducer = combineReducers<IRootState>({
  authentication,
  locale,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  passwordReset,
  password,
  settings,
  page,
  section,
  item,
  works,
  workTags,
  jobs,
  application,
  articles,
  articleCategories,
  articleTags,
  language,
  news,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar,
});

export default rootReducer;
