import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate, TextFormat, getSortState, IPaginationBaseState, JhiPagination, JhiItemCount, Storage } from 'react-jhipster';
import { Button, Col, Row, Table } from 'reactstrap';

import { APP_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import axios from 'axios';

export const Jobs = (props: any) => {
  const [jobsList, setJobsList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE, 'id'), props.location.search)
  );

  const locale = Storage.session.get('locale', 'en');
  const apiUrl = `api/jobs/${locale}`;

  const getEntities = async (page: any, size: any, sort: any) => {
    const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
    const result = await axios.get<any>(requestUrl);
    setJobsList(result?.data?.items);
    setTotalItems(result?.data?.total);
  };

  const getAllEntities = () => {
    getEntities(paginationState.activePage, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`);
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get('sort');
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const truncate = (str: any, n: any) => {
    return str?.length > n ? str.substr(0, n - 1) + '...' : str;
  };

  const { match, loading } = props;
  return (
    <div>
      <h2 id="jobs-heading" data-cy="JobsHeading">
        <Translate contentKey="wataInternalWebApp.jobs.home.title">Jobs</Translate>
        <div className="d-flex justify-content-end">
          <Button hidden={true} className="mr-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="wataInternalWebApp.jobs.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to={`${match.url}/new`} className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="wataInternalWebApp.jobs.home.createLabel">Create new Jobs</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {jobsList && jobsList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="wataInternalWebApp.jobs.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('title')}>
                  <Translate contentKey="wataInternalWebApp.jobs.title">Title</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('titleTag')}>
                  <Translate contentKey="wataInternalWebApp.jobs.titleTag">Title Tag</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th hidden={true} className="hand" onClick={sort('slug')}>
                  <Translate contentKey="wataInternalWebApp.jobs.slug">Slug</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('description')}>
                  <Translate contentKey="wataInternalWebApp.jobs.description">Meta Description</Translate> <FontAwesomeIcon icon="sort" />
                </th>
				<th hidden={false} className="hand" onClick={sort('image')}>
                  <Translate contentKey="wataInternalWebApp.jobs.image">Image</Translate> <FontAwesomeIcon icon="sort" />
                </th>
				<th className="hand">Short Content</th>
                <th className="hand" onClick={sort('validFrom')}>
                  <Translate contentKey="wataInternalWebApp.jobs.validFrom">Valid From</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('validThrough')}>
                  <Translate contentKey="wataInternalWebApp.jobs.validThrough">Valid Through</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('status')}>
                  <Translate contentKey="wataInternalWebApp.jobs.status">Status</Translate> <FontAwesomeIcon icon="sort" />
                </th>
				<th className="hand">
				  <Translate contentKey="wataInternalWebApp.jobs.langCode">Lang Code</Translate>
				</th>
                <th hidden={true} className="hand" onClick={sort('createdBy')}>
                  <Translate contentKey="wataInternalWebApp.jobs.createdBy">Created By</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th hidden={true} className="hand" onClick={sort('createdDate')}>
                  <Translate contentKey="wataInternalWebApp.jobs.createdDate">Created Date</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th hidden={true} className="hand" onClick={sort('postedDate')}>
                  <Translate contentKey="wataInternalWebApp.jobs.postedDate">Posted Date</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th hidden={true} className="hand" onClick={sort('updatedDate')}>
                  <Translate contentKey="wataInternalWebApp.jobs.updatedDate">Updated Date</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th hidden={true} className="hand" onClick={sort('updatedBy')}>
                  <Translate contentKey="wataInternalWebApp.jobs.updatedBy">Updated By</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {jobsList.map((jobs, i) => {
                const strippedHtml = jobs?.content?.replace(/<[^>]+>/g, '');

                return (
                  <tr key={`entity-${i}`} data-cy="entityTable">
                    <td>
                      <Button tag={Link} to={`${match.url}/${jobs.id}`} color="link" size="sm">
                        {jobs.id}
                      </Button>
                    </td>
                    <td>{jobs.title}</td>
                    <td>{jobs.titleTag}</td>
                    <td>{jobs.description}</td>
                    <td hidden={true}>{jobs.slug}</td>
                    <td hidden={false}>
						<img className=" img-fluid rounded small_image" src={jobs?.image}></img>
					</td>
                    <td>{truncate(strippedHtml, 20)}</td>
                    <td>{jobs.validFrom ? <TextFormat type="date" value={jobs.validFrom} format={APP_DATE_FORMAT} /> : null}</td>
                    <td>{jobs.validThrough ? <TextFormat type="date" value={jobs.validThrough} format={APP_DATE_FORMAT} /> : null}</td>
                    <td>
                      <Translate contentKey={`wataInternalWebApp.JobStatus.${jobs.status}`} />
                    </td>
					<td>
					  {jobs.langCode === 'en' ? 
					  <div className="btn-group flex-btn-group-container">
                        <Button
                          tag={Link}
                          to={`${match.url}/${jobs.id}/edit?lang=ja&page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                          color="info"
                          size="sm"
                          data-cy="entityEditButton"
                        >
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.editJP">JP</Translate>
                          </span>
                        </Button>
                        <Button
                          tag={Link}
                          to={`${match.url}/${jobs.id}/edit?lang=vi&page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                          color="primary"
                          size="sm"
                          data-cy="entityEditButton"
                        >
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.editVN">VN</Translate>
                          </span>
                        </Button>
					  </div>
					  : null
					  }
					</td>
                    <td hidden={true}>{jobs.createdBy}</td>
                    <td hidden={true}>
                      {jobs.createdDate ? <TextFormat type="date" value={jobs.createdDate} format={APP_DATE_FORMAT} /> : null}
                    </td>
                    <td hidden={true}>
                      {jobs.postedDate ? <TextFormat type="date" value={jobs.postedDate} format={APP_DATE_FORMAT} /> : null}
                    </td>
                    <td hidden={true}>
                      {jobs.updatedDate ? <TextFormat type="date" value={jobs.updatedDate} format={APP_DATE_FORMAT} /> : null}
                    </td>
                    <td hidden={true}>{jobs.updatedBy}</td>
                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <Button tag={Link} to={`${match.url}/${jobs.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>
                        <Button
                          tag={Link}
                          to={`${match.url}/${jobs.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                          color="primary"
                          size="sm"
                          data-cy="entityEditButton"
                        >
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                        <Button
                          tag={Link}
                          to={`${match.url}/${jobs.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                          color="danger"
                          size="sm"
                          data-cy="entityDeleteButton"
                        >
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="wataInternalWebApp.jobs.home.notFound">No Jobs found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={jobsList && jobsList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Jobs;
