import dayjs from 'dayjs';
import { IArticleCategories } from 'app/shared/model/article-categories.model';
import { IArticleTags } from 'app/shared/model/article-tags.model';
import { IUser } from 'app/shared/model/user.model';
import { ArticleStatus } from 'app/shared/model/enumerations/article-status.model';

export interface IArticles {
  id?: number;
  title?: string;
  titleTag?: string;
  slug?: string;
  thumbnail?: string | null;
  excerpt?: string | null;
  content?: string | null;
  status?: ArticleStatus | null;
  dateCreated?: string | null;
  publishedDate?: string | null;
  updatedDate?: string | null;
  numberOfView?: number | null;
  author?: string | null;
  category?: IArticleCategories | null;
  articleTags?: IArticleTags[] | null;
  poster?: IUser | null;
  editor?: IUser | null;
}

export const defaultValue: Readonly<IArticles> = {};
