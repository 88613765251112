import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvField, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {Translate, translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IRootState} from 'app/shared/reducers';
import {getEntities as getSections} from 'app/entities/section/section.reducer';
import {createEntity, getEntity, reset, updateEntity} from './item.reducer';
import {convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime} from 'app/shared/util/date-utils';

export interface IItemUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const ItemUpdate = (props: IItemUpdateProps) => {
  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const {itemEntity, sections, loading, updating} = props;

  const handleClose = () => {
    props.history.push('/item' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getSections();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    values.createdDate = convertDateTimeToServer(values.createdDate);
    values.updatedDate = convertDateTimeToServer(values.updatedDate);

    if (errors.length === 0) {
      const entity = {
        ...itemEntity,
        ...values,
        section: sections.find(it => it.id.toString() === values.sectionId.toString()),
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="wataInternalWebApp.item.home.createOrEditLabel" data-cy="ItemCreateUpdateHeading">
            <Translate contentKey="wataInternalWebApp.item.home.createOrEditLabel">Create or edit a Item</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : itemEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="item-id">
                    <Translate contentKey="global.field.id">ID</Translate>
                  </Label>
                  <AvInput id="item-id" type="text" className="form-control" name="id" required readOnly/>
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="titleLabel" for="item-title">
                  <Translate contentKey="wataInternalWebApp.item.title">Title</Translate>
                </Label>
                <AvField
                  id="item-title"
                  data-cy="title"
                  type="text"
                  name="title"
                  validate={{
                    required: {value: true, errorMessage: translate('entity.validation.required')},
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="metaDataLabel" for="item-metaData">
                  <Translate contentKey="wataInternalWebApp.item.metaData">Meta Data</Translate>
                </Label>
                <AvField id="item-metaData" data-cy="metaData" type="text" name="metaData"/>
              </AvGroup>
              <AvGroup>
                <Label id="descriptionLabel" for="item-description">
                  <Translate contentKey="wataInternalWebApp.item.description">Description</Translate>
                </Label>
                <AvField id="item-description" data-cy="description" type="text" name="description"/>
              </AvGroup>
              <AvGroup>
                <Label id="iconLabel" for="item-icon">
                  <Translate contentKey="wataInternalWebApp.item.icon">Icon</Translate>
                </Label>
                <AvField id="item-icon" data-cy="icon" type="text" name="icon"/>
              </AvGroup>
              <AvGroup>
                <Label id="featureImageLabel" for="item-featureImage">
                  <Translate contentKey="wataInternalWebApp.item.featureImage">Feature Image</Translate>
                </Label>
                <AvField id="item-featureImage" data-cy="featureImage" type="text" name="featureImage"/>
              </AvGroup>
              <AvGroup>
                <Label id="linkToLabel" for="item-linkTo">
                  <Translate contentKey="wataInternalWebApp.item.linkTo">Link To</Translate>
                </Label>
                <AvField id="item-linkTo" data-cy="linkTo" type="text" name="linkTo"/>
              </AvGroup>
              <AvGroup>
                <Label id="createdByLabel" for="item-createdBy">
                  <Translate contentKey="wataInternalWebApp.item.createdBy">Created By</Translate>
                </Label>
                <AvField id="item-createdBy" data-cy="createdBy" type="string" className="form-control"
                         name="createdBy"/>
              </AvGroup>
              <AvGroup>
                <Label id="createdDateLabel" for="item-createdDate">
                  <Translate contentKey="wataInternalWebApp.item.createdDate">Created Date</Translate>
                </Label>
                <AvInput
                  id="item-createdDate"
                  data-cy="createdDate"
                  type="datetime-local"
                  className="form-control"
                  name="createdDate"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.itemEntity.createdDate)}
                />
              </AvGroup>
              <AvGroup>
                <Label id="updatedDateLabel" for="item-updatedDate">
                  <Translate contentKey="wataInternalWebApp.item.updatedDate">Updated Date</Translate>
                </Label>
                <AvInput
                  id="item-updatedDate"
                  data-cy="updatedDate"
                  type="datetime-local"
                  className="form-control"
                  name="updatedDate"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.itemEntity.updatedDate)}
                />
              </AvGroup>
              <AvGroup>
                <Label id="updatedByLabel" for="item-updatedBy">
                  <Translate contentKey="wataInternalWebApp.item.updatedBy">Updated By</Translate>
                </Label>
                <AvField id="item-updatedBy" data-cy="updatedBy" type="string" className="form-control"
                         name="updatedBy"/>
              </AvGroup>
              <AvGroup>
                <Label for="item-section">
                  <Translate contentKey="wataInternalWebApp.item.section">Section</Translate>
                </Label>
                <AvInput id="item-section" data-cy="section" type="select" className="form-control" name="sectionId">
                  <option value="" key="0"/>
                  {sections
                    ? sections.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                    : null}
                </AvInput>
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/item" replace color="info">
                <FontAwesomeIcon icon="arrow-left"/>
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit"
                      disabled={updating}>
                <FontAwesomeIcon icon="save"/>
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  sections: storeState.section.entities,
  itemEntity: storeState.item.entity,
  loading: storeState.item.loading,
  updating: storeState.item.updating,
  updateSuccess: storeState.item.updateSuccess,
});

const mapDispatchToProps = {
  getSections,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ItemUpdate);
