import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup, CustomInput, Table } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import axios from 'axios';
import { toast } from 'react-toastify';
import { Dictionary } from 'lodash';
export const ArticlesPopular = (props: any) => {
  const [articlesList, setArticlesList] = useState([]);
  const [idSelect, setIdSelect] = useState([]);
  const [rSelected, setRSelected] = useState(null);

  const addSelected = (id: any) => {
    if (idSelect.includes(id)) {
      const newList = idSelect.filter(e => e !== id);
      setIdSelect(newList);
    }
    if (!idSelect.includes(id)) {
      setIdSelect(oldArray => [...oldArray, id]);
    }
  };

  const viewPopularUrl = 'api/articles/popular';
  const getPopularList = async (popularStt: string) => {
    setRSelected(popularStt);
    const result = await axios.post<any>(viewPopularUrl, { key: popularStt });
    if (result) {
      toast.success('get list popular success');
      setArticlesList(result?.data?.items);
    }
  };

  const allArticlesUrl = 'api/articles/all';
  const getAllEntities = async () => {
    const result = await axios.get<any>(allArticlesUrl);
    if (result) {
      const { popularItems, items } = result?.data;
      const idList = popularItems.map((item: { id: any }) => item?.id);
      setArticlesList(items);
      toast.success('get list popular success');
    }
  };

  const popularStatusUrl = 'api/articles/popular-status';
  const getPolpularStt = async () => {
    const result = await axios.get<any>(popularStatusUrl);
    const popularStt = result?.data?.status;
    setRSelected(popularStt);

    if (popularStt === 'latest' || popularStt === 'viewed') {
      await getPopularList(popularStt);
    }
    if (popularStt === 'manual') {
      await getAllEntities();
    }
  };

  const selectPopularUrl = 'api/articles/popular';
  const handlePopularIds = async () => {
    // default payload luôn gửi đên BE, để biết cái loại mà admin muốn hiển thị trên tragn Home. rSelected là cái tab tên gì.
    const payload: Record<string, string | string[] | boolean> = {
      rSelected, // id của Tab select, enum 'latest' | 'viewed' | 'manual'
      submitClick: true,
    };
    if (rSelected === 'manual') {
      // Nếu tab hiện tại admin chọn để submit là manual thì add thêm ids vô payload
      // trước khi add, check xem đã chọn 3 ids trên UI chưa
      // console.log(idSelect.length);
      if (idSelect.length < 3) return toast.error('please choose 3 items');
      if (idSelect.length > 3) return toast.error('please only choose 3 items');

      payload.ids = idSelect; // add manual id, nếu rSelect đang lưu ở db là manual thì return về 3 cái ids này
      payload.key = 'manual';
    }
    if (rSelected === 'latest') {
      payload.key = 'latest';
    }
    if (rSelected === 'viewed') {
      payload.key = 'viewed';
    }
    const result = await axios.post<any>(selectPopularUrl, payload); // gửi payload đến BE
    if (result) {
      toast.success('get list popular success');
    }
  };

  useEffect(() => {
    getPolpularStt();
  }, []);

  return (
    <div>
      <h2 id="articles-heading" data-cy="ArticlesHeading">
        Articles Popular
        <div className="d-flex justify-content-end">
          <ButtonGroup size="sm">
            <Button
              color={rSelected === 'latest' ? 'primary' : 'secondary'}
              onClick={() => getPopularList('latest')}
              active={rSelected === 'latest'}
              style={{ margin: '5px' }}
            >
              Latest Popular
            </Button>
            <Button
              color={rSelected === 'viewed' ? 'primary' : 'secondary'}
              onClick={() => getPopularList('viewed')}
              active={rSelected === 'viewed'}
              style={{ margin: '5px' }}
            >
              Viewed Popular
            </Button>
            <Button
              color={rSelected === 'manual' ? 'primary' : 'secondary'}
              onClick={() => {
                setRSelected('manual');
                getAllEntities();
              }}
              active={rSelected === 'manual'}
              style={{ margin: '5px' }}
            >
              Manual Popular
            </Button>
            <Button style={{ margin: '5px' }} color="warning" onClick={() => handlePopularIds()}>
              Submit
            </Button>
          </ButtonGroup>
        </div>
      </h2>
      <div className="table-responsive">
        {articlesList && articlesList.length > 0 && (
          <Table responsive>
            <thead>
              <tr>
                {rSelected === 'manual' && <th className="hand" />}
                <th className="hand">
                  <Translate contentKey="wataInternalWebApp.articles.id">ID</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="wataInternalWebApp.articles.title">Title</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="wataInternalWebApp.articles.slug">Slug</Translate>{' '}
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {articlesList.map((articles, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  {rSelected === 'manual' && (
                    <td>
                      <div>
                        <input
                          type="checkbox"
                          id="vehicle1"
                          name="vehicle1"
                          value="Bike"
                          onClick={() => {
                            addSelected(articles?.id);
                          }}
                        />
                      </div>
                    </td>
                  )}
                  <td>
                    <div>{articles.id}</div>
                  </td>
                  <td>{articles.title}</td>
                  <td>{articles.slug}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default ArticlesPopular;
