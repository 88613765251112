import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {TextFormat, Translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntity} from './application.reducer';
import {APP_DATE_FORMAT} from 'app/config/constants';

export interface IApplicationDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const ApplicationDetail = (props: IApplicationDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const {applicationEntity} = props;
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="applicationDetailsHeading">
          <Translate contentKey="wataInternalWebApp.application.detail.title">Application</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{applicationEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="wataInternalWebApp.application.name">Name</Translate>
            </span>
          </dt>
          <dd>{applicationEntity.name}</dd>
          <dt>
            <span id="phoneNumber">
              <Translate contentKey="wataInternalWebApp.application.phoneNumber">Phone Number</Translate>
            </span>
          </dt>
          <dd>{applicationEntity.phoneNumber}</dd>
          <dt>
            <span id="email">
              <Translate contentKey="wataInternalWebApp.application.email">Email</Translate>
            </span>
          </dt>
          <dd>{applicationEntity.email}</dd>
          <dt>
            <span id="attachedDocuments">
              <Translate contentKey="wataInternalWebApp.application.attachedDocuments">Attached Documents</Translate>
            </span>
          </dt>
          <dd>{applicationEntity.attachedDocuments}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="wataInternalWebApp.application.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>
            {applicationEntity.createdDate ? (
              <TextFormat value={applicationEntity.createdDate} type="date" format={APP_DATE_FORMAT}/>
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="wataInternalWebApp.application.jobs">Jobs</Translate>
          </dt>
          <dd>{applicationEntity.jobs ? applicationEntity.jobs.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/application" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left"/>{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/application/${applicationEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt"/>{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({application}: IRootState) => ({
  applicationEntity: application.entity,
});

const mapDispatchToProps = {getEntity};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationDetail);
