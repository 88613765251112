import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { TextFormat, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './section.reducer';
import { APP_DATE_FORMAT } from 'app/config/constants';

export interface ISectionDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const SectionDetail = (props: ISectionDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { sectionEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="sectionDetailsHeading">
          <Translate contentKey="wataInternalWebApp.section.detail.title">Section</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{sectionEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="wataInternalWebApp.section.name">Name</Translate>
            </span>
          </dt>
          <dd>{sectionEntity.name}</dd>
          <dt>
            <span id="title">
              <Translate contentKey="wataInternalWebApp.section.title">Title</Translate>
            </span>
          </dt>
          <dd>{sectionEntity.title}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="wataInternalWebApp.section.description">Description</Translate>
            </span>
          </dt>
          <dd>{sectionEntity.description}</dd>
          <dt>
            <span id="background">
              <Translate contentKey="wataInternalWebApp.section.background">Background</Translate>
            </span>
          </dt>
          <dd>{sectionEntity.background}</dd>
          <dt>
            <span id="featureimage">
              <Translate contentKey="wataInternalWebApp.section.image">Featureimage</Translate>
            </span>
          </dt>
          <dd>{sectionEntity.image}</dd>
          <dt>
            <span id="type">
              <Translate contentKey="wataInternalWebApp.section.type">Type</Translate>
            </span>
          </dt>
          <dd>{sectionEntity.type}</dd>
          <dt>
            <span id="numberOfItems">
              <Translate contentKey="wataInternalWebApp.section.numberOfItems">Number Of Items</Translate>
            </span>
          </dt>
          <dd>{sectionEntity.numberOfItems}</dd>
          <dt>
            <span id="textColor">
              <Translate contentKey="wataInternalWebApp.section.textColor">Text Color</Translate>
            </span>
          </dt>
          <dd>{sectionEntity.textColor}</dd>
          <dt>
            <span id="layout">
              <Translate contentKey="wataInternalWebApp.section.layout">Layout</Translate>
            </span>
          </dt>
          <dd>{sectionEntity.layout}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="wataInternalWebApp.section.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{sectionEntity.createdBy}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="wataInternalWebApp.section.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>
            {sectionEntity.createdDate ? <TextFormat value={sectionEntity.createdDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="updatedDate">
              <Translate contentKey="wataInternalWebApp.section.updatedDate">Updated Date</Translate>
            </span>
          </dt>
          <dd>
            {sectionEntity.updatedDate ? <TextFormat value={sectionEntity.updatedDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="updatedBy">
              <Translate contentKey="wataInternalWebApp.section.updatedBy">Updated By</Translate>
            </span>
          </dt>
          <dd>{sectionEntity.updatedBy}</dd>
          <dt>
            <Translate contentKey="wataInternalWebApp.section.page">Page</Translate>
          </dt>
          <dd>{sectionEntity.page ? sectionEntity.page.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/section" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/section/${sectionEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ section }: IRootState) => ({
  sectionEntity: section.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SectionDetail);
