import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { stateFromHTML } from 'draft-js-import-html';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntity, getEntityJP, getEntityVN, updateEntity, createEntity, reset } from './news.reducer';
import { INews } from 'app/shared/model/news.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import { makeSlug } from 'app/shared/util/entity-utils';
import draftToHtml from 'draftjs-to-html';


export interface INewsUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const NewsUpdate = (props: INewsUpdateProps) => {
  const [isNew] = useState(!props.match.params || !props.match.params.id);
  const [showEmptyContentImg] = useState(false);
  const [thumbUrl, setThumbUrl] = useState(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [slug, setSlug] = useState('');

  const { newsEntity, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/news' + props.location.search);
  };

  const onChangeUploadThumbnail = async (event: any) => {
    const file = event?.target?.files[0];

    const data = new FormData();
    data.append('file', file);

    const result = await axios.post('/api/news/upload-image', data, {});
    const { link } = result.data;
    if (link) {
      setThumbUrl(link);
      toast.success('Upload image success!');
    }
  };

  const onChangeTitle = event => {
    if (isNew) {
      setSlug(() => makeSlug(event.target.value));
    }
  };

  useEffect(() => {
    // console.log("use effect");

    if (newsEntity?.thumbnail) {
      setThumbUrl(newsEntity?.thumbnail);
    }
  }, [newsEntity]);

  useEffect(() => {
    if (isNew) {
      props.reset();
	  setThumbUrl(null);
    } else {
      const params = new URLSearchParams(props.location.search);
	  const lang = params.get('lang');
	  if (lang === null) {
		props.getEntity(props.match.params.id);
	  } else if (lang === 'ja') {
		props.getEntityJP(props.match.params.id);
	  } else if (lang === 'vi') {
		props.getEntityVN(props.match.params.id);
	  }

    }
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  useEffect(() => {
    if (!isNew && newsEntity?.description) {
      const htmlContent = newsEntity?.description;
      const convertedState = stateFromHTML(htmlContent);
      const editorValue = EditorState.createWithContent(convertedState);
      setEditorState(editorValue);
    }
  }, [newsEntity]);

  const saveEntity = (event, errors, values) => {
    values.createdDate = convertDateTimeToServer(values.createdDate);
    values.publishedDate = convertDateTimeToServer(values.publishedDate);
    values.updatedDate = convertDateTimeToServer(values.updatedDate);

    if (errors.length === 0) {
      const entity = {
        ...newsEntity,
        ...values,
      };

	  if (thumbUrl) {
        entity.thumbnail = thumbUrl;
      }

	  const contentVal = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      entity.description = contentVal;

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  const onEditorStateChange = val => {
    setEditorState(val);
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="wataInternalWebApp.news.home.createOrEditLabel" data-cy="NewsCreateUpdateHeading">
            <Translate contentKey="wataInternalWebApp.news.home.createOrEditLabel">Create or edit a News</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : newsEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="news-id">
                    <Translate contentKey="global.field.id">ID</Translate>
                  </Label>
                  <AvInput id="news-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="titleLabel" for="news-title">
                  <Translate contentKey="wataInternalWebApp.news.title">Title</Translate>
				  <span className="required"> *</span>
                </Label>
                <AvField
                  id="news-title"
                  data-cy="title"
                  type="text"
                  name="title"
				  onChange={onChangeTitle}
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="webTitleLabel" for="news-webTitle">
                  <Translate contentKey="wataInternalWebApp.news.webTitle">Web Title</Translate> (Article Origin)
                </Label>
                <AvField id="news-webTitle" data-cy="webTitle" type="text" name="webTitle" placeholder="Ex: CafeBiz.vn"/>
              </AvGroup>
              <AvGroup>
                <Label id="slugLabel" for="news-slug">
                  <Translate contentKey="wataInternalWebApp.news.slug">Slug</Translate>
				  <span className="required"> *</span>
                </Label>
                <AvField
                  id="news-slug"
                  data-cy="slug"
                  type="text"
                  name="slug"
				  value={slug} 
				  disabled={!isNew} 
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                  }}
                />
              </AvGroup>
			   <AvGroup>
                <Label id="shortDescriptionLabel" for="news-shortDescription">
                  <Translate contentKey="wataInternalWebApp.news.shortDescription">shortDescription</Translate>
				  <span className="required"> *</span>
                </Label>
                <AvField
                  id="news-shortDescription"
                  data-cy="shortDescription"
                  type="textarea"
                  name="shortDescription"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: translate('entity.validation.required'),
                    },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="descriptionLabel" for="news-description">
                  <Translate contentKey="wataInternalWebApp.news.description">Description</Translate>
                </Label>
                <Editor
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                  editorStyle={{
                    border: '1px solid #F1F1F1',
                    minHeight: '100px',
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="thumbnailLabel" for="news-thumbnail">
                  <Translate contentKey="wataInternalWebApp.news.thumbnail">Thumbnail</Translate>
				  <span className="required"> *</span>
				  <br />
                  <input id="news-thumbnail" data-cy="thumbnail" name="thumbnail" type="file" onChange={onChangeUploadThumbnail} hidden />
	                <label
	                  htmlFor="news-thumbnail"
	                  style={{
	                    background: 'indigo',
	                    color: 'white',
	                    padding: '0.5rem',
	                    borderRadius: '0.3rem',
	                  }}
	                >
	                  Choose File
	                </label>
	                {
	                  <div className="invalid-feedback" style={{ marginLeft: '1rem' }} hidden={!showEmptyContentImg || thumbUrl}>
	                    <Translate contentKey="entity.validation.required"></Translate>
	                  </div>
	                }
	                <span style={{ marginLeft: '1rem' }}>{thumbUrl}</span>
                </Label>
                {/*<AvField id="news-thumbnail" data-cy="thumbnail" type="text" name="thumbnail" />*/}
              </AvGroup>
              <AvGroup>
                <Label id="typeLabel" for="news-type">
                  <Translate contentKey="wataInternalWebApp.news.type">Type</Translate>
                </Label>
                <AvInput
                  id="news-type"
                  data-cy="type"
                  type="select"
                  className="form-control"
                  name="type"
                  value={(!isNew && newsEntity.type) || 'REVIEWED'}
                >
                  <option value="REVIEWED">{translate('wataInternalWebApp.NewsType.REVIEWED')}</option>
                  <option value="OTHER_ACTIVITIES">{translate('wataInternalWebApp.NewsType.OTHER_ACTIVITIES')}</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="statusLabel" for="news-status">
                  <Translate contentKey="wataInternalWebApp.news.status">Status</Translate>
                </Label>
                <AvInput
                  id="news-status"
                  data-cy="status"
                  type="select"
                  className="form-control"
                  name="status"
                  value={(!isNew && newsEntity.status) || 'DRAFT'}
                >
                  <option value="DRAFT">{translate('wataInternalWebApp.NewsStatus.DRAFT')}</option>
                  <option value="TO_BE_APPROVED">{translate('wataInternalWebApp.NewsStatus.TO_BE_APPROVED')}</option>
                  <option value="PUBLISHED">{translate('wataInternalWebApp.NewsStatus.PUBLISHED')}</option>
                  <option value="TRASHED">{translate('wataInternalWebApp.NewsStatus.TRASHED')}</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="authorLabel" for="news-author">
                  <Translate contentKey="wataInternalWebApp.news.author">Author</Translate>
                </Label>
                <AvField id="news-author" data-cy="author" type="text" name="author" />
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/news" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  newsEntity: storeState.news.entity,
  loading: storeState.news.loading,
  updating: storeState.news.updating,
  updateSuccess: storeState.news.updateSuccess,
});

const mapDispatchToProps = {
  getEntity,
  getEntityJP,
  getEntityVN,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(NewsUpdate);
