import { IItem } from 'app/shared/model/item.model';
import { IPage } from 'app/shared/model/page.model';
import { TextColor } from 'app/shared/model/enumerations/text-color.model';
import { SectionLayout } from 'app/shared/model/enumerations/section-layout.model';

export interface ISection {
  id?: number;
  name?: string | null;
  title?: string | null;
  description?: string | null;
  background?: string | null;
  image?: string | null;
  type?: string;
  numberOfItems?: number;
  textColor?: TextColor;
  layout?: SectionLayout | null;
  createdBy?: number | null;
  createdDate?: string | null;
  updatedDate?: string | null;
  updatedBy?: number | null;
  items?: IItem[] | null;
  page?: IPage | null;
}

export const defaultValue: Readonly<ISection> = {};
