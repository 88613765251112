import React from 'react';
import {Switch} from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ArticleCategories from './article-categories';
import ArticleCategoriesDetail from './article-categories-detail';
import ArticleCategoriesUpdate from './article-categories-update';
import ArticleCategoriesDeleteDialog from './article-categories-delete-dialog';

const Routes = ({match}) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ArticleCategoriesUpdate}/>
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ArticleCategoriesUpdate}/>
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ArticleCategoriesDetail}/>
      <ErrorBoundaryRoute path={match.url} component={ArticleCategories}/>
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ArticleCategoriesDeleteDialog}/>
  </>
);

export default Routes;
