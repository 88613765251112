import React from 'react';
import {Switch} from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Page from './page';
import Section from './section';
import Item from './item';
import Works from './works';
import WorkTags from './work-tags';
import Jobs from './jobs';
import Application from './application';
import Articles from './articles';
import ArticleCategories from './article-categories';
import ArticleTags from './article-tags';
import ErrorBoundaryRoute from "app/shared/error/error-boundary-route";
/* jhipster-needle-add-route-import - JHipster will add routes here */

const Routes = ({match}) => (
  <div>
    <Switch>
      {/* prettier-ignore */}
      <ErrorBoundaryRoute path={`${match.url}page`} component={Page}/>
      <ErrorBoundaryRoute path={`${match.url}section`} component={Section}/>
      <ErrorBoundaryRoute path={`${match.url}item`} component={Item}/>
      <ErrorBoundaryRoute path={`${match.url}works`} component={Works}/>
      <ErrorBoundaryRoute path={`${match.url}work-tags`} component={WorkTags}/>
      <ErrorBoundaryRoute path={`${match.url}jobs`} component={Jobs}/>
      <ErrorBoundaryRoute path={`${match.url}application`} component={Application}/>
      <ErrorBoundaryRoute path={`${match.url}articles`} component={Articles}/>
      <ErrorBoundaryRoute path={`${match.url}article-categories`} component={ArticleCategories}/>
      <ErrorBoundaryRoute path={`${match.url}article-tags`} component={ArticleTags}/>
      {/* jhipster-needle-add-route-path - JHipster will add routes here */}
    </Switch>
  </div>
);

export default Routes;
