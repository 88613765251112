import dayjs from 'dayjs';

export interface INews {
  id?: number;
  title?: string;
  webTitle?: string | null;
  slug?: string;
  shortDescription?: string | null;
  description?: string | null;
  image?: string | null;
  thumbnail?: string | null;
  type?: string | null;
  status?: string | null;
  createdBy?: number | null;
  createdDate?: string | null;
  publishedDate?: string | null;
  updatedDate?: string | null;
  updatedBy?: number | null;
  author?: string | null;
  langCode?: string;
  parentId?: number | null;
}

export const defaultValue: Readonly<INews> = {};
