import { IWorks } from 'app/shared/model/works.model';

export interface IWorkTags {
  id?: number;
  label?: string;
  slug?: string;
  createdDate?: string | null;
  createdBy?: number | null;
}

export const defaultValue: Readonly<IWorkTags> = {};
