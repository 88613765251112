import dayjs from 'dayjs';

export interface IArticleTags {
  id?: number;
  label?: string;
  slug?: string;
  createdDate?: string | null;
  createdBy?: number | null;
}

export const defaultValue: Readonly<IArticleTags> = {};
