import dayjs from 'dayjs';

export interface ILanguage {
  id?: number;
  langCode?: string;
  langTitle?: string;
  createdDate?: string | null;
  createdBy?: number | null;
}

export const defaultValue: Readonly<ILanguage> = {};
