import dayjs from 'dayjs';
import { IApplication } from 'app/shared/model/application.model';
import { JobStatus } from 'app/shared/model/enumerations/job-status.model';

export interface IJobs {
  id?: number;
  title?: string;
  slug?: string;
  image?: string | null;
  content?: string | null;
  validFrom?: string | null;
  validThrough?: string | null;
  status?: JobStatus | null;
  createdBy?: number | null;
  createdDate?: string | null;
  postedDate?: string | null;
  updatedDate?: string | null;
  updatedBy?: number | null;
  isNew?: boolean | null;
  applications?: IApplication[] | null;
}

export const defaultValue: Readonly<IJobs> = {};
