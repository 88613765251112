import dayjs from 'dayjs';
import { IWorkTags } from 'app/shared/model/work-tags.model';
import { IUser } from 'app/shared/model/user.model';
import { WorkStatus } from 'app/shared/model/enumerations/work-status.model';

export interface IWorks {
  id?: number;
  title?: string;
  titleTag?: string;
  description?: string | null;
  slug?: string;
  thumbnail?: string | null;
  excerpt?: string;
  content?: string | null;
  status?: WorkStatus;
  createdBy?: number | null;
  createdDate?: string | null;
  publishedDate?: string | null;
  updatedDate?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  updatedBy?: number | null;
  workTags?: IWorkTags[] | null;
  author?: IUser | null;
}

export const defaultValue: Readonly<IWorks> = {};
