import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { getEntity, getEntityJP, getEntityVN, updateEntity, createEntity, reset } from './works.reducer';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { makeSlug } from 'app/shared/util/entity-utils';
import axios from 'axios';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { stateFromHTML } from 'draft-js-import-html';
import { toast } from 'react-toastify';

export interface IWorksUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const WorksUpdate = (props: IWorksUpdateProps) => {
  const [isNew] = useState(!props.match.params || !props.match.params.id);
  const [showEmptyContentImg] = useState(false);
  const [showEmpty, setShowEmpty] = useState(false);
  const [thumbUrl, setThumbUrl] = useState(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [slug, setSlug] = useState('');

  const { worksEntity, users, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/works' + props.location.search);
  };
  const onChangeUploadThumbnail = async (event: any) => {
    const file = event?.target?.files[0];

    const data = new FormData();
    data.append('file', file);

    const result = await axios.post('/api/work/upload-image', data, {});
    const { link } = result.data;
    if (link) {
      setThumbUrl(link);
      toast.success('Upload image success!');
    }
  };

  const onChangeTitle = event => {
    if (isNew) {
      setSlug(() => makeSlug(event.target.value));
    }
  };

  useEffect(() => {
    // console.log("use effect");

    if (worksEntity?.thumbnail) {
      setThumbUrl(worksEntity?.thumbnail);
    }
  }, [worksEntity]);

  useEffect(() => {
    if (isNew) {
      props.reset();
      setThumbUrl(null);
    } else {
	  const params = new URLSearchParams(props.location.search);
	  const lang = params.get('lang');
	  if (lang === null) {
		props.getEntity(props.match.params.id);
	  } else if (lang === 'ja') {
		props.getEntityJP(props.match.params.id);
	  } else if (lang === 'vi') {
		props.getEntityVN(props.match.params.id);
	  }
      
    }
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  useEffect(() => {
    if (!isNew && worksEntity?.content) {
      const htmlContent = worksEntity?.content;
      const convertedState = stateFromHTML(htmlContent);
      const editorValue = EditorState.createWithContent(convertedState);
      setEditorState(editorValue);
    }
  }, [worksEntity]);

  const saveEntity = (event, errors, values) => {
    values.createdDate = convertDateTimeToServer(values.createdDate);
    values.publishedDate = convertDateTimeToServer(values.publishedDate);
    values.updatedDate = convertDateTimeToServer(values.updatedDate);
    values.startDate = convertDateTimeToServer(values.startDate);
    values.endDate = convertDateTimeToServer(values.endDate);

    if (errors.length === 0) {
      const entity = {
        ...worksEntity,
        ...values,
        author: users.find(it => it.id.toString() === values.authorId.toString()),
      };
      //
      // if (imgUrl) {
      //   entity.image = imgUrl;
      // } else {
      //   setShowEmptyBanner(true);
      // }

      if (!editorState.getCurrentContent().hasText()) {
        setShowEmpty(true);
      }

      if (thumbUrl) {
        entity.thumbnail = thumbUrl;
      }

      if (!thumbUrl || !editorState.getCurrentContent().hasText()) {
        return;
      }

      const contentVal = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      entity.content = contentVal;

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  const onEditorStateChange = val => {
    setEditorState(val);
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="wataInternalWebApp.works.home.createOrEditLabel" data-cy="WorksCreateUpdateHeading">
            <Translate contentKey="wataInternalWebApp.works.home.createOrEditLabel">Create or edit a Works</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : worksEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="works-id">
                    <Translate contentKey="global.field.id">ID </Translate>
                  </Label>
                  <AvInput id="works-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="titleLabel" for="works-title">
                  <Translate contentKey="wataInternalWebApp.works.title">Title</Translate>
                  <span className="required"> *</span>
                </Label>
                <AvField
                  id="works-title"
                  data-cy="title"
                  type="text"
                  name="title"
                  onChange={onChangeTitle}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: translate('entity.validation.required'),
                    },
                    maxLength: { value: 100, errorMessage: translate('entity.validation.max') },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="titleTagLabel" for="works-title-tag">
                  <Translate contentKey="wataInternalWebApp.works.titleTag">Title Tag</Translate>
                  <span className="required"> *</span>
                </Label>
                <AvField
                  id="works-title-tag"
                  data-cy="titleTag"
                  type="text"
                  name="titleTag"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: translate('entity.validation.required'),
                    },
                    maxLength: { value: 100, errorMessage: translate('entity.validation.max') },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="descriptionLabel" for="works-description">
                  <Translate contentKey="wataInternalWebApp.works.description">Description</Translate>
                </Label>
                <span className="required"> *</span>
                <AvField
                  id="works-description"
                  data-cy="description"
                  type="textarea"
                  name="description"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: translate('entity.validation.required'),
                    },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="slugLabel" for="works-slug">
                  <Translate contentKey="wataInternalWebApp.works.slug">Slug</Translate>
                </Label>
                <AvField id="works-slug" data-cy="slug" type="text" name="slug" value={slug} disabled={!isNew} />
              </AvGroup>
              {/* upload thumbnail */}
              <AvGroup>
                <Label id="thumbnailLabel" for="works-thumbnail">
                  <Translate contentKey="wataInternalWebApp.works.thumbnail">Content Image</Translate>
                </Label>
                <span className="required"> *</span>
                <br />
                <input id="works-thumbnail" data-cy="thumbnail" name="thumbnail" type="file" onChange={onChangeUploadThumbnail} hidden />
                <label
                  htmlFor="works-thumbnail"
                  style={{
                    background: 'indigo',
                    color: 'white',
                    padding: '0.5rem',
                    borderRadius: '0.3rem',
                  }}
                >
                  Choose File
                </label>
                {
                  <div className="invalid-feedback" style={{ marginLeft: '1rem' }} hidden={!showEmptyContentImg || thumbUrl}>
                    <Translate contentKey="entity.validation.required"></Translate>
                  </div>
                }
                <span style={{ marginLeft: '1rem' }}>{thumbUrl}</span>
              </AvGroup>
              {/* end */}
              <AvGroup>
                <Label id="contentAltLabel" for="works-content-alt">
                  <Translate contentKey="wataInternalWebApp.works.contentAlt">Content Image Alt</Translate>
                </Label>
                <AvField id="works-content-alt" data-cy="contentAlt" type="text" name="contentAlt" />
              </AvGroup>
              <AvGroup>
                <Label id="excerptLabel" for="works-excerpt">
                  <Translate contentKey="wataInternalWebApp.works.excerpt">Excerpt</Translate>
                </Label>
                <span className="required"> *</span>
                <AvField
                  id="works-excerpt"
                  data-cy="excerpt"
                  type="text"
                  name="excerpt"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: translate('entity.validation.required'),
                    },
                  }}
                />
              </AvGroup>
              <div>
                <Label id="contentLabel" for="works-content">
                  <Translate contentKey="wataInternalWebApp.works.content">Content</Translate>
                </Label>
                <span className="required"> *</span>
                {/* <AvField id="works-content" data-cy="content" type="text" name="content" /> */}
                <Editor
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                  editorStyle={{
                    border: '1px solid #F1F1F1',
                    minHeight: '100px',
                  }}
                />
                {
                  <div className="invalid-feedback" hidden={!showEmpty}>
                    <Translate contentKey="entity.validation.required"></Translate>
                  </div>
                }
              </div>
              <AvGroup>
                <Label id="statusLabel" for="works-status">
                  <Translate contentKey="wataInternalWebApp.works.status">Status</Translate>
                </Label>
                <AvInput
                  id="works-status"
                  data-cy="status"
                  type="select"
                  className="form-control"
                  name="status"
                  value={(!isNew && worksEntity.status) || 'DRAFT'}
                >
                  <option value="DRAFT">{translate('wataInternalWebApp.WorkStatus.DRAFT')}</option>
                  <option value="TO_BE_APPROVED">{translate('wataInternalWebApp.WorkStatus.TO_BE_APPROVED')}</option>
                  <option value="PUBLISHED">{translate('wataInternalWebApp.WorkStatus.PUBLISHED')}</option>
                  <option value="TRASHED">{translate('wataInternalWebApp.WorkStatus.TRASHED')}</option>
                </AvInput>
              </AvGroup>
              <AvGroup hidden={true}>
                <Label id="createdByLabel" for="works-createdBy">
                  <Translate contentKey="wataInternalWebApp.works.createdBy">Created By</Translate>
                </Label>
                <AvField id="works-createdBy" data-cy="createdBy" type="string" className="form-control" name="createdBy" />
              </AvGroup>
              <AvGroup hidden={true}>
                <Label id="createdDateLabel" for="works-createdDate">
                  <Translate contentKey="wataInternalWebApp.works.createdDate">Created Date</Translate>
                </Label>
                <AvInput
                  id="works-createdDate"
                  data-cy="createdDate"
                  type="datetime-local"
                  className="form-control"
                  name="createdDate"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.worksEntity.createdDate)}
                />
              </AvGroup>
              <AvGroup hidden={true}>
                <Label id="publishedDateLabel" for="works-publishedDate">
                  <Translate contentKey="wataInternalWebApp.works.publishedDate">Published Date</Translate>
                </Label>
                <AvInput
                  id="works-publishedDate"
                  data-cy="publishedDate"
                  type="datetime-local"
                  className="form-control"
                  name="publishedDate"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.worksEntity.publishedDate)}
                />
              </AvGroup>
              <AvGroup hidden={true}>
                <Label id="updatedDateLabel" for="works-updatedDate">
                  <Translate contentKey="wataInternalWebApp.works.updatedDate">Updated Date</Translate>
                </Label>
                <AvInput
                  id="works-updatedDate"
                  data-cy="updatedDate"
                  type="datetime-local"
                  className="form-control"
                  name="updatedDate"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.worksEntity.updatedDate)}
                />
              </AvGroup>
              <AvGroup>
                <Label id="startDateLabel" for="works-startDate">
                  <Translate contentKey="wataInternalWebApp.works.startDate">Start Date</Translate>
                </Label>
                <AvInput
                  id="works-startDate"
                  data-cy="startDate"
                  type="datetime-local"
                  className="form-control"
                  name="startDate"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.worksEntity.startDate)}
                />
              </AvGroup>
              <AvGroup>
                <Label id="endDateLabel" for="works-endDate">
                  <Translate contentKey="wataInternalWebApp.works.endDate">End Date</Translate>
                </Label>
                <span className="required"> *</span>
                <AvInput
                  id="works-endDate"
                  data-cy="endDate"
                  type="datetime-local"
                  className="form-control"
                  name="endDate"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.worksEntity.endDate)}
                />
              </AvGroup>
              <AvGroup hidden={true}>
                <Label id="updatedByLabel" for="works-updatedBy">
                  <Translate contentKey="wataInternalWebApp.works.updatedBy">Updated By</Translate>
                </Label>
                <AvField id="works-updatedBy" data-cy="updatedBy" type="string" className="form-control" name="updatedBy" />
              </AvGroup>
              <AvGroup hidden={true}>
                <Label for="works-author">
                  <Translate contentKey="wataInternalWebApp.works.author">Author</Translate>
                </Label>
                <AvInput
                  id="works-author"
                  data-cy="author"
                  type="select"
                  className="form-control"
                  name="authorId"
                  value={worksEntity?.author?.id}
                >
                  <option value="" key="0" />
                  {users
                    ? users.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.name}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <AvGroup check>
                <Label check>
                  <AvInput type="checkbox" name="isTableContent" />
                  <Translate contentKey="wataInternalWebApp.works.isTableContent"> Table Content </Translate>
                </Label>
              </AvGroup>
              <br></br>
              <Button tag={Link} id="cancel-save" to="/works" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  users: storeState.userManagement.users,
  worksEntity: storeState.works.entity,
  loading: storeState.works.loading,
  updating: storeState.works.updating,
  updateSuccess: storeState.works.updateSuccess,
});
const mapDispatchToProps = {
  getUsers,
  getEntity,
  getEntityJP,
  getEntityVN,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(WorksUpdate);
