import axios from 'axios';
import {ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction} from 'react-jhipster';

import {cleanEntity} from 'app/shared/util/entity-utils';
import {FAILURE, REQUEST, SUCCESS} from 'app/shared/reducers/action-type.util';

import {defaultValue, IWorkTags} from 'app/shared/model/work-tags.model';

export const ACTION_TYPES = {
  FETCH_WORKTAGS_LIST: 'workTags/FETCH_WORKTAGS_LIST',
  FETCH_WORKTAGS: 'workTags/FETCH_WORKTAGS',
  CREATE_WORKTAGS: 'workTags/CREATE_WORKTAGS',
  UPDATE_WORKTAGS: 'workTags/UPDATE_WORKTAGS',
  PARTIAL_UPDATE_WORKTAGS: 'workTags/PARTIAL_UPDATE_WORKTAGS',
  DELETE_WORKTAGS: 'workTags/DELETE_WORKTAGS',
  RESET: 'workTags/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IWorkTags>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type WorkTagsState = Readonly<typeof initialState>;

// Reducer

export default (state: WorkTagsState = initialState, action): WorkTagsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_WORKTAGS_LIST):
    case REQUEST(ACTION_TYPES.FETCH_WORKTAGS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_WORKTAGS):
    case REQUEST(ACTION_TYPES.UPDATE_WORKTAGS):
    case REQUEST(ACTION_TYPES.DELETE_WORKTAGS):
    case REQUEST(ACTION_TYPES.PARTIAL_UPDATE_WORKTAGS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_WORKTAGS_LIST):
    case FAILURE(ACTION_TYPES.FETCH_WORKTAGS):
    case FAILURE(ACTION_TYPES.CREATE_WORKTAGS):
    case FAILURE(ACTION_TYPES.UPDATE_WORKTAGS):
    case FAILURE(ACTION_TYPES.PARTIAL_UPDATE_WORKTAGS):
    case FAILURE(ACTION_TYPES.DELETE_WORKTAGS):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_WORKTAGS_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_WORKTAGS):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_WORKTAGS):
    case SUCCESS(ACTION_TYPES.UPDATE_WORKTAGS):
    case SUCCESS(ACTION_TYPES.PARTIAL_UPDATE_WORKTAGS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_WORKTAGS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/work-tags';

// Actions

export const getEntities: ICrudGetAllAction<IWorkTags> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_WORKTAGS_LIST,
    payload: axios.get<IWorkTags>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IWorkTags> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_WORKTAGS,
    payload: axios.get<IWorkTags>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IWorkTags> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_WORKTAGS,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IWorkTags> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_WORKTAGS,
    payload: axios.put(`${apiUrl}/${entity.id}`, cleanEntity(entity)),
  });
  return result;
};

export const partialUpdate: ICrudPutAction<IWorkTags> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.PARTIAL_UPDATE_WORKTAGS,
    payload: axios.patch(`${apiUrl}/${entity.id}`, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IWorkTags> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_WORKTAGS,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
