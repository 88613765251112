import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './article-tags.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IArticleTagsDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ArticleTagsDetail = (props: IArticleTagsDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { articleTagsEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="articleTagsDetailsHeading">
          <Translate contentKey="wataInternalWebApp.articleTags.detail.title">ArticleTags</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{articleTagsEntity.id}</dd>
          <dt>
            <span id="label">
              <Translate contentKey="wataInternalWebApp.articleTags.label">Label</Translate>
            </span>
          </dt>
          <dd>{articleTagsEntity.label}</dd>
          <dt>
            <span id="slug">
              <Translate contentKey="wataInternalWebApp.articleTags.slug">Slug</Translate>
            </span>
          </dt>
          <dd>{articleTagsEntity.slug}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="wataInternalWebApp.articleTags.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>
            {articleTagsEntity.createdDate ? (
              <TextFormat value={articleTagsEntity.createdDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="wataInternalWebApp.articleTags.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{articleTagsEntity.createdBy}</dd>
        </dl>
        <Button tag={Link} to="/article-tags" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/article-tags/${articleTagsEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ articleTags }: IRootState) => ({
  articleTagsEntity: articleTags.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ArticleTagsDetail);
