import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_DATE_FORMAT } from 'app/config/constants';
import axios from 'axios';
import { toast } from 'react-toastify';

import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateFromHTML } from 'draft-js-import-html';

export const ArticlesDetail = (props: any) => {
  const [articlesEntity, setArticlesEntity] = useState({
    id: null,
    title: null,
    description: null,
    slug: null,
    image: null,
    thumbnail: null,
    excerpt: null,
    content: null,
    status: null,
    dateCreated: null,
    publishedDate: null,
    updatedDate: null,
    numberOfView: null,
    author: null,
    category: null,
    poster: null,
    editor: null,
    contentAlt: null,
    isTableContent: null,
    titleTag: null,
  });

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const apiUrlV2 = 'api/article';

  const getEntity = async id => {
    const requestUrl = `${apiUrlV2}?id=${id}`;
    const result = await axios.get<any>(requestUrl);
    if (result) {
      toast.success('Success!');
    }
    setArticlesEntity(result?.data);
  };

  useEffect(() => {
    getEntity(props?.match?.params?.id);
  }, []);

  useEffect(() => {
    if (articlesEntity?.content) {
      const htmlContent = articlesEntity?.content;
      const convertedState = stateFromHTML(htmlContent);
      const editorValue = EditorState.createWithContent(convertedState);
      setEditorState(editorValue);
    }
  }, [articlesEntity]);

  return (
    <Row>
      <Col md="8">
        <h2 data-cy="articlesDetailsHeading">
          <Translate contentKey="wataInternalWebApp.articles.detail.title">Articles</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{articlesEntity.id}</dd>
          <dt>
            <span id="title">
              <Translate contentKey="wataInternalWebApp.articles.title">Title</Translate>
            </span>
          </dt>
          <dd>{articlesEntity.title}</dd>
          <dt>
            <span id="titleTag">
              <Translate contentKey="wataInternalWebApp.articles.titleTag">Title Tag</Translate>
            </span>
          </dt>
          <dd>{articlesEntity.titleTag}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="wataInternalWebApp.articles.description">Meta Description</Translate>
            </span>
          </dt>
          <dd>{articlesEntity.description}</dd>
          <dt>
            <span id="slug">
              <Translate contentKey="wataInternalWebApp.articles.slug">Slug</Translate>
            </span>
          </dt>
          <dd>{articlesEntity.slug}</dd>
          <dt>
            <span id="thumbnail">
              <Translate contentKey="wataInternalWebApp.articles.thumbnail">Content Image</Translate>
            </span>
          </dt>
          <dd>{articlesEntity.thumbnail}</dd>
          <dt>
            <span id="contentAlt">
              <Translate contentKey="wataInternalWebApp.articles.contentAlt">Content Image Alt</Translate>
            </span>
          </dt>
          <dd>{articlesEntity.contentAlt}</dd>
          <dt>
            <span id="excerpt">
              <Translate contentKey="wataInternalWebApp.articles.excerpt">Excerpt</Translate>
            </span>
          </dt>
          <dd>{articlesEntity.excerpt}</dd>
          <dt>
            <span id="content">
              <Translate contentKey="wataInternalWebApp.articles.content">Content</Translate>
            </span>
          </dt>
          {/* <dd>{articlesEntity.content}</dd> */}
          <Editor editorState={editorState} wrapperClassName="demo-wrapper" editorClassName="demo-editor" readOnly />
          <dt>
            <span id="status">
              <Translate contentKey="wataInternalWebApp.articles.status">Status</Translate>
            </span>
          </dt>
          <dd>{articlesEntity.status}</dd>
          <dt>
            <span id="dateCreated">
              <Translate contentKey="wataInternalWebApp.articles.dateCreated">Date Created</Translate>
            </span>
          </dt>
          <dd>
            {articlesEntity.dateCreated ? <TextFormat value={articlesEntity.dateCreated} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="publishedDate">
              <Translate contentKey="wataInternalWebApp.articles.publishedDate">Published Date</Translate>
            </span>
          </dt>
          <dd>
            {articlesEntity.publishedDate ? <TextFormat value={articlesEntity.publishedDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="updatedDate">
              <Translate contentKey="wataInternalWebApp.articles.updatedDate">Updated Date</Translate>
            </span>
          </dt>
          <dd>
            {articlesEntity.updatedDate ? <TextFormat value={articlesEntity.updatedDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="numberOfView">
              <Translate contentKey="wataInternalWebApp.articles.numberOfView">Number Of View</Translate>
            </span>
          </dt>
          <dd>{articlesEntity.numberOfView}</dd>
          <dt>
            <span id="author">
              <Translate contentKey="wataInternalWebApp.articles.author">Author</Translate>
            </span>
          </dt>
          <dd>{articlesEntity?.author?.name}</dd>
          <dt>
            <Translate contentKey="wataInternalWebApp.articles.category">Category</Translate>
          </dt>
          <dd>{articlesEntity.category ? articlesEntity.category.name : ''}</dd>
          <dt>
            <Translate contentKey="wataInternalWebApp.articles.poster">Poster</Translate>
          </dt>
          <dd>{articlesEntity.poster ? articlesEntity.poster.name : ''}</dd>
          <dt>
            <Translate contentKey="wataInternalWebApp.articles.editor">Editor</Translate>
          </dt>
          <dd>{articlesEntity.editor ? articlesEntity.editor.name : ''}</dd>
          <dt>
            <Translate contentKey="wataInternalWebApp.articles.isTableContent">Table Content</Translate>
          </dt>
          <dd>{articlesEntity.isTableContent ? 'Yes' : 'No'}</dd>
        </dl>
        <Button tag={Link} to="/articles" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/articles/${articlesEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ArticlesDetail;
