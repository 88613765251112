import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { NavDropdown } from './menu-components';
import MenuItem from 'app/shared/layout/menus/menu-item';

export const EntitiesMenu = props => (
  <NavDropdown
    icon="th-list"
    name={translate('global.menu.entities.main')}
    id="entity-menu"
    data-cy="entity"
    style={{ maxHeight: '80vh', overflow: 'auto' }}
  >
    <MenuItem isHidden={true} icon="asterisk" to="/page">
      <Translate contentKey="global.menu.entities.page" />
    </MenuItem>
    <MenuItem isHidden={true} icon="asterisk" to="/section">
      <Translate contentKey="global.menu.entities.section" />
    </MenuItem>
    <MenuItem isHidden={true} icon="asterisk" to="/item">
      <Translate contentKey="global.menu.entities.item" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/works">
      <Translate contentKey="global.menu.entities.works" />
    </MenuItem>
    <MenuItem isHidden={true} icon="asterisk" to="/work-tags">
      <Translate contentKey="global.menu.entities.workTags" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/jobs">
      <Translate contentKey="global.menu.entities.jobs" />
    </MenuItem>
    <MenuItem isHidden={true} icon="asterisk" to="/application">
      <Translate contentKey="global.menu.entities.application" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/articles">
      <Translate contentKey="global.menu.entities.articles" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/article-categories">
      <Translate contentKey="global.menu.entities.articleCategories" />
    </MenuItem>
    <MenuItem isHidden={true} icon="asterisk" to="/article-tags">
      <Translate contentKey="global.menu.entities.articleTags" />
    </MenuItem>
	<MenuItem icon="asterisk" to="/news">
      <Translate contentKey="global.menu.entities.news" />
    </MenuItem>
    {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
  </NavDropdown>
);
