import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, IArticleCategories } from 'app/shared/model/article-categories.model';

export const ACTION_TYPES = {
  FETCH_ALL_ARTICLECATEGORIES_LIST: 'articleCategories/FETCH_ALL_ARTICLECATEGORIES_LIST',
  FETCH_ARTICLECATEGORIES_LIST: 'articleCategories/FETCH_ARTICLECATEGORIES_LIST',
  FETCH_ARTICLECATEGORIES: 'articleCategories/FETCH_ARTICLECATEGORIES',
  CREATE_ARTICLECATEGORIES: 'articleCategories/CREATE_ARTICLECATEGORIES',
  UPDATE_ARTICLECATEGORIES: 'articleCategories/UPDATE_ARTICLECATEGORIES',
  PARTIAL_UPDATE_ARTICLECATEGORIES: 'articleCategories/PARTIAL_UPDATE_ARTICLECATEGORIES',
  DELETE_ARTICLECATEGORIES: 'articleCategories/DELETE_ARTICLECATEGORIES',
  RESET: 'articleCategories/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IArticleCategories>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type ArticleCategoriesState = Readonly<typeof initialState>;

// Reducer

export default (state: ArticleCategoriesState = initialState, action): ArticleCategoriesState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ARTICLECATEGORIES_LIST):
    case REQUEST(ACTION_TYPES.FETCH_ARTICLECATEGORIES):
    case REQUEST(ACTION_TYPES.FETCH_ALL_ARTICLECATEGORIES_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_ARTICLECATEGORIES):
    case REQUEST(ACTION_TYPES.UPDATE_ARTICLECATEGORIES):
    case REQUEST(ACTION_TYPES.DELETE_ARTICLECATEGORIES):
    case REQUEST(ACTION_TYPES.PARTIAL_UPDATE_ARTICLECATEGORIES):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_ARTICLECATEGORIES_LIST):
    case FAILURE(ACTION_TYPES.FETCH_ALL_ARTICLECATEGORIES_LIST):
    case FAILURE(ACTION_TYPES.FETCH_ARTICLECATEGORIES):
    case FAILURE(ACTION_TYPES.CREATE_ARTICLECATEGORIES):
    case FAILURE(ACTION_TYPES.UPDATE_ARTICLECATEGORIES):
    case FAILURE(ACTION_TYPES.PARTIAL_UPDATE_ARTICLECATEGORIES):
    case FAILURE(ACTION_TYPES.DELETE_ARTICLECATEGORIES):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_ARTICLECATEGORIES_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data.items,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_ALL_ARTICLECATEGORIES_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_ARTICLECATEGORIES):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_ARTICLECATEGORIES):
    case SUCCESS(ACTION_TYPES.UPDATE_ARTICLECATEGORIES):
    case SUCCESS(ACTION_TYPES.PARTIAL_UPDATE_ARTICLECATEGORIES):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_ARTICLECATEGORIES):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/article-categories';
const apiUrl2 = 'api/article-categories/all';

// Actions

export const getEntities: ICrudGetAllAction<IArticleCategories> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_ARTICLECATEGORIES_LIST,
    payload: axios.get<IArticleCategories>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getAllEntities: ICrudGetAllAction<IArticleCategories> = (page, size, sort) => {
  const requestUrl = `${apiUrl2}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_ALL_ARTICLECATEGORIES_LIST,
    payload: axios.get<IArticleCategories>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IArticleCategories> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_ARTICLECATEGORIES,
    payload: axios.get<IArticleCategories>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IArticleCategories> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_ARTICLECATEGORIES,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IArticleCategories> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_ARTICLECATEGORIES,
    payload: axios.put(`${apiUrl}/${entity.id}`, cleanEntity(entity)),
  });
  return result;
};

export const partialUpdate: ICrudPutAction<IArticleCategories> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.PARTIAL_UPDATE_ARTICLECATEGORIES,
    payload: axios.patch(`${apiUrl}/${entity.id}`, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IArticleCategories> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_ARTICLECATEGORIES,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
