import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntities as getPages } from 'app/entities/page/page.reducer';
import { createEntity, getEntity, reset, updateEntity } from './section.reducer';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';

export interface ISectionUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const SectionUpdate = (props: ISectionUpdateProps) => {
  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const { sectionEntity, pages, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/section' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getPages();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    values.createdDate = convertDateTimeToServer(values.createdDate);
    values.updatedDate = convertDateTimeToServer(values.updatedDate);

    if (errors.length === 0) {
      const entity = {
        ...sectionEntity,
        ...values,
        page: pages.find(it => it.id.toString() === values.pageId.toString()),
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="wataInternalWebApp.section.home.createOrEditLabel" data-cy="SectionCreateUpdateHeading">
            <Translate contentKey="wataInternalWebApp.section.home.createOrEditLabel">Create or edit a Section</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : sectionEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="section-id">
                    <Translate contentKey="global.field.id">ID</Translate>
                  </Label>
                  <AvInput id="section-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="nameLabel" for="section-name">
                  <Translate contentKey="wataInternalWebApp.section.name">Name</Translate>
                </Label>
                <AvField id="section-name" data-cy="name" type="text" name="name" />
              </AvGroup>
              <AvGroup>
                <Label id="titleLabel" for="section-title">
                  <Translate contentKey="wataInternalWebApp.section.title">Title</Translate>
                </Label>
                <AvField id="section-title" data-cy="title" type="text" name="title" />
              </AvGroup>
              <AvGroup>
                <Label id="descriptionLabel" for="section-description">
                  <Translate contentKey="wataInternalWebApp.section.description">Description</Translate>
                </Label>
                <AvField id="section-description" data-cy="description" type="text" name="description" />
              </AvGroup>
              <AvGroup>
                <Label id="backgroundLabel" for="section-background">
                  <Translate contentKey="wataInternalWebApp.section.background">Background</Translate>
                </Label>
                <AvField id="section-background" data-cy="background" type="text" name="background" />
              </AvGroup>
              <AvGroup>
                <Label id="featureimageLabel" for="section-featureimage">
                  <Translate contentKey="wataInternalWebApp.section.image">Featureimage</Translate>
                </Label>
                <AvField id="section-featureimage" data-cy="featureimage" type="text" name="featureimage" />
              </AvGroup>
              <AvGroup>
                <Label id="typeLabel" for="section-type">
                  <Translate contentKey="wataInternalWebApp.section.type">Type</Translate>
                </Label>
                <AvField
                  id="section-type"
                  data-cy="type"
                  type="text"
                  name="type"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="numberOfItemsLabel" for="section-numberOfItems">
                  <Translate contentKey="wataInternalWebApp.section.numberOfItems">Number Of Items</Translate>
                </Label>
                <AvField
                  id="section-numberOfItems"
                  data-cy="numberOfItems"
                  type="string"
                  className="form-control"
                  name="numberOfItems"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    number: { value: true, errorMessage: translate('entity.validation.number') },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="textColorLabel" for="section-textColor">
                  <Translate contentKey="wataInternalWebApp.section.textColor">Text Color</Translate>
                </Label>
                <AvInput
                  id="section-textColor"
                  data-cy="textColor"
                  type="select"
                  className="form-control"
                  name="textColor"
                  value={(!isNew && sectionEntity.textColor) || 'DARK'}
                >
                  <option value="DARK">{translate('wataInternalWebApp.TextColor.DARK')}</option>
                  <option value="LIGHT">{translate('wataInternalWebApp.TextColor.LIGHT')}</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="layoutLabel" for="section-layout">
                  <Translate contentKey="wataInternalWebApp.section.layout">Layout</Translate>
                </Label>
                <AvInput
                  id="section-layout"
                  data-cy="layout"
                  type="select"
                  className="form-control"
                  name="layout"
                  value={(!isNew && sectionEntity.layout) || 'COL2'}
                >
                  <option value="COL2">{translate('wataInternalWebApp.SectionLayout.COL2')}</option>
                  <option value="COL3">{translate('wataInternalWebApp.SectionLayout.COL3')}</option>
                  <option value="COL4">{translate('wataInternalWebApp.SectionLayout.COL4')}</option>
                  <option value="COL6">{translate('wataInternalWebApp.SectionLayout.COL6')}</option>
                  <option value="L12">{translate('wataInternalWebApp.SectionLayout.L12')}</option>
                  <option value="R12">{translate('wataInternalWebApp.SectionLayout.R12')}</option>
                  <option value="L13">{translate('wataInternalWebApp.SectionLayout.L13')}</option>
                  <option value="R13">{translate('wataInternalWebApp.SectionLayout.R13')}</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="createdByLabel" for="section-createdBy">
                  <Translate contentKey="wataInternalWebApp.section.createdBy">Created By</Translate>
                </Label>
                <AvField id="section-createdBy" data-cy="createdBy" type="string" className="form-control" name="createdBy" />
              </AvGroup>
              <AvGroup>
                <Label id="createdDateLabel" for="section-createdDate">
                  <Translate contentKey="wataInternalWebApp.section.createdDate">Created Date</Translate>
                </Label>
                <AvInput
                  id="section-createdDate"
                  data-cy="createdDate"
                  type="datetime-local"
                  className="form-control"
                  name="createdDate"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.sectionEntity.createdDate)}
                />
              </AvGroup>
              <AvGroup>
                <Label id="updatedDateLabel" for="section-updatedDate">
                  <Translate contentKey="wataInternalWebApp.section.updatedDate">Updated Date</Translate>
                </Label>
                <AvInput
                  id="section-updatedDate"
                  data-cy="updatedDate"
                  type="datetime-local"
                  className="form-control"
                  name="updatedDate"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.sectionEntity.updatedDate)}
                />
              </AvGroup>
              <AvGroup>
                <Label id="updatedByLabel" for="section-updatedBy">
                  <Translate contentKey="wataInternalWebApp.section.updatedBy">Updated By</Translate>
                </Label>
                <AvField id="section-updatedBy" data-cy="updatedBy" type="string" className="form-control" name="updatedBy" />
              </AvGroup>
              <AvGroup>
                <Label for="section-page">
                  <Translate contentKey="wataInternalWebApp.section.page">Page</Translate>
                </Label>
                <AvInput id="section-page" data-cy="page" type="select" className="form-control" name="pageId">
                  <option value="" key="0" />
                  {pages
                    ? pages.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.id}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/section" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  pages: storeState.page.entities,
  sectionEntity: storeState.section.entity,
  loading: storeState.section.loading,
  updating: storeState.section.updating,
  updateSuccess: storeState.section.updateSuccess,
});

const mapDispatchToProps = {
  getPages,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SectionUpdate);
