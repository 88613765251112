import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Row, Table } from 'reactstrap';
import { Translate, TextFormat, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import axios from 'axios';
import { toast } from 'react-toastify';

export const Articles = (props: any) => {
  const [articlesList, setArticlesList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const apiUrl = 'api/articles';

  const getEntities = async (page: any, size: any, sort: any) => {
    const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
    const result = await axios.get<any>(requestUrl);
    setArticlesList(result?.data?.items);
    setTotalItems(result?.data?.total);
  };

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE, 'id'), props.location.search)
  );

  const getAllEntities = () => {
    getEntities(paginationState.activePage, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`);
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get('sort');
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const truncate = (str: any, n: any) => {
    return str?.length > n ? str.substr(0, n - 1) + '...' : str;
  };

  const { match, loading } = props;
  return (
    <div>
      <h2 id="articles-heading" data-cy="ArticlesHeading">
        <Translate contentKey="wataInternalWebApp.articles.home.title">Articles</Translate>
        <div className="d-flex justify-content-end">
          <Link
            to={`${match.url}/popular`}
            className="btn btn-primary jh-create-entity mr-2"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            Tech Blog
          </Link>
          <Button hidden={true} className="mr-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="wataInternalWebApp.articles.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to={`${match.url}/new`} className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="wataInternalWebApp.articles.home.createLabel">Create new Articles</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {articlesList && articlesList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="wataInternalWebApp.articles.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('title')}>
                  <Translate contentKey="wataInternalWebApp.articles.title">Title</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('titleTag')}>
                  <Translate contentKey="wataInternalWebApp.articles.titleTag">Title Tag</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('title')}>
                  <Translate contentKey="wataInternalWebApp.articles.description">Meta Description</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th hidden={true} className="hand" onClick={sort('slug')}>
                  <Translate contentKey="wataInternalWebApp.articles.slug">Slug</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('thumbnail')}>
                  <Translate contentKey="wataInternalWebApp.articles.thumbnail">Content Image</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('excerpt')}>
                  <Translate contentKey="wataInternalWebApp.articles.excerpt">Short Description</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('content')}>
                  <Translate contentKey="wataInternalWebApp.articles.content">Content</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('status')}>
                  <Translate contentKey="wataInternalWebApp.articles.status">Status</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th hidden={true} className="hand" onClick={sort('dateCreated')}>
                  <Translate contentKey="wataInternalWebApp.articles.dateCreated">Date Created</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th hidden={true} className="hand" onClick={sort('publishedDate')}>
                  <Translate contentKey="wataInternalWebApp.articles.publishedDate">Published Date</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th hidden={true} className="hand" onClick={sort('updatedDate')}>
                  <Translate contentKey="wataInternalWebApp.articles.updatedDate">Updated Date</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('numberOfView')}>
                  <Translate contentKey="wataInternalWebApp.articles.numberOfView">Number Of View</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('author')}>
                  <Translate contentKey="wataInternalWebApp.articles.author">Author</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="wataInternalWebApp.articles.category">Category</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th hidden={true}>
                  <Translate contentKey="wataInternalWebApp.articles.poster">Poster</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th hidden={true}>
                  <Translate contentKey="wataInternalWebApp.articles.editor">Editor</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {articlesList.map((articles, i) => {
                const strippedHtml = articles?.content?.replace(/<[^>]+>/g, '');

                return (
                  <tr key={`entity-${i}`} data-cy="entityTable">
                    <td>
                      <Button tag={Link} to={`${match.url}/${articles.id}`} color="link" size="sm">
                        {articles.id}
                      </Button>
                    </td>
                    <td>{articles.title}</td>
                    <td>{articles.titleTag}</td>
                    <td>{articles.description}</td>
                    <td hidden={true}>{articles.slug}</td>
                    {/* <td>{articles?.image?.split('/').pop()}</td>
                    <td>{articles?.thumbnail?.split('/').pop()}</td> */}
                    <td>
                      <img className=" img-fluid rounded small_image" src={articles?.thumbnail}></img>
                    </td>
                    <td>{articles.excerpt}</td>
                    <td>{truncate(strippedHtml, 20)}</td>
                    <td>
                      <Translate contentKey={`wataInternalWebApp.ArticleStatus.${articles.status}`} />
                    </td>
                    <td hidden={true}>
                      {articles.dateCreated ? <TextFormat type="date" value={articles.dateCreated} format={APP_DATE_FORMAT} /> : null}
                    </td>
                    <td hidden={true}>
                      {articles.publishedDate ? <TextFormat type="date" value={articles.publishedDate} format={APP_DATE_FORMAT} /> : null}
                    </td>
                    <td hidden={true}>
                      {articles.updatedDate ? <TextFormat type="date" value={articles.updatedDate} format={APP_DATE_FORMAT} /> : null}
                    </td>
                    <td>{articles.numberOfView}</td>
                    <td>{articles.author.name}</td>
                    <td>
                      {articles.category ? <Link to={`article-categories/${articles.category.id}`}>{articles.category.id}</Link> : ''}
                    </td>
                    <td hidden={true}>{articles.poster ? articles.poster.name : ''}</td>
                    <td hidden={true}>{articles.editor ? articles.editor.name : ''}</td>
                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <Button tag={Link} to={`${match.url}/${articles.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>
                        <Button
                          tag={Link}
                          to={`${match.url}/${articles.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                          color="primary"
                          size="sm"
                          data-cy="entityEditButton"
                        >
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                        <Button
                          tag={Link}
                          to={`${match.url}/${articles.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                          color="danger"
                          size="sm"
                          data-cy="entityDeleteButton"
                        >
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="wataInternalWebApp.articles.home.notFound">No Articles found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={articlesList && articlesList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Articles;
