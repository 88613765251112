import {ISection} from 'app/shared/model/section.model';
import {PageTemplate} from 'app/shared/model/enumerations/page-template.model';
import {PageType} from 'app/shared/model/enumerations/page-type.model';

export interface IPage {
  id?: number;
  name?: string;
  title?: string | null;
  slug?: string;
  template?: PageTemplate;
  featureImage?: string | null;
  thumbnail?: string | null;
  description?: string | null;
  keyword?: string | null;
  type?: PageType | null;
  url?: string | null;
  image?: string | null;
  createdBy?: number | null;
  createdDate?: string | null;
  updatedBy?: number | null;
  updatedDate?: string | null;
  sections?: ISection[] | null;
}

export const defaultValue: Readonly<IPage> = {};
