export interface IArticleCategories {
  id?: number;
  // label?: string;
  name?: string;
  slug?: string;
  createdDate?: string | null;
  createdBy?: number | null;
}

export const defaultValue: Readonly<IArticleCategories> = {};
