import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { createEntity, getEntity, reset, updateEntity } from './page.reducer';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';

export interface IPageUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const PageUpdate = (props: IPageUpdateProps) => {
  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const { pageEntity, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/page' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    values.createdDate = convertDateTimeToServer(values.createdDate);
    values.updatedDate = convertDateTimeToServer(values.updatedDate);

    if (errors.length === 0) {
      const entity = {
        ...pageEntity,
        ...values,
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="wataInternalWebApp.page.home.createOrEditLabel" data-cy="PageCreateUpdateHeading">
            <Translate contentKey="wataInternalWebApp.page.home.createOrEditLabel">Create or edit a Page</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : pageEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="page-id">
                    <Translate contentKey="global.field.id">ID</Translate>
                  </Label>
                  <AvInput id="page-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="nameLabel" for="page-name">
                  <Translate contentKey="wataInternalWebApp.page.name">Name</Translate>
                </Label>
                <AvField
                  id="page-name"
                  data-cy="name"
                  type="text"
                  name="name"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="titleLabel" for="page-title">
                  <Translate contentKey="wataInternalWebApp.page.title">Title</Translate>
                </Label>
                <AvField id="page-title" data-cy="title" type="text" name="title" />
              </AvGroup>
              <AvGroup>
                <Label id="slugLabel" for="page-slug">
                  <Translate contentKey="wataInternalWebApp.page.slug">Slug</Translate>
                </Label>
                <AvField id="page-slug" data-cy="slug" type="text" name="slug" />
              </AvGroup>
              <AvGroup>
                <Label id="templateLabel" for="page-template">
                  <Translate contentKey="wataInternalWebApp.page.template">Template</Translate>
                </Label>
                <AvInput
                  id="page-template"
                  data-cy="template"
                  type="select"
                  className="form-control"
                  name="template"
                  value={(!isNew && pageEntity.template) || 'LITERAL'}
                >
                  <option value="LITERAL">{translate('wataInternalWebApp.PageTemplate.LITERAL')}</option>
                  <option value="ARTICLES">{translate('wataInternalWebApp.PageTemplate.ARTICLES')}</option>
                  <option value="WORKS">{translate('wataInternalWebApp.PageTemplate.WORKS')}</option>
                  <option value="JOBS">{translate('wataInternalWebApp.PageTemplate.JOBS')}</option>
                  <option value="CONTACT">{translate('wataInternalWebApp.PageTemplate.CONTACT')}</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="featureImageLabel" for="page-featureImage">
                  <Translate contentKey="wataInternalWebApp.page.featureImage">Feature Image</Translate>
                </Label>
                <AvField id="page-featureImage" data-cy="featureImage" type="text" name="featureImage" />
              </AvGroup>
              <AvGroup>
                <Label id="thumbnailLabel" for="page-thumbnail">
                  <Translate contentKey="wataInternalWebApp.page.thumbnail">Thumbnail</Translate>
                </Label>
                <AvField id="page-thumbnail" data-cy="thumbnail" type="text" name="thumbnail" />
              </AvGroup>
              <AvGroup>
                <Label id="descriptionLabel" for="page-description">
                  <Translate contentKey="wataInternalWebApp.page.description">Description</Translate>
                </Label>
                <AvField id="page-description" data-cy="description" type="text" name="description" />
              </AvGroup>
              <AvGroup>
                <Label id="keywordLabel" for="page-keyword">
                  <Translate contentKey="wataInternalWebApp.page.keyword">Keyword</Translate>
                </Label>
                <AvField id="page-keyword" data-cy="keyword" type="text" name="keyword" />
              </AvGroup>
              <AvGroup>
                <Label id="typeLabel" for="page-type">
                  <Translate contentKey="wataInternalWebApp.page.type">Type</Translate>
                </Label>
                <AvInput
                  id="page-type"
                  data-cy="type"
                  type="select"
                  className="form-control"
                  name="type"
                  value={(!isNew && pageEntity.type) || 'WEBSITE'}
                >
                  <option value="WEBSITE">{translate('wataInternalWebApp.PageType.WEBSITE')}</option>
                  <option value="ARTICLE">{translate('wataInternalWebApp.PageType.ARTICLE')}</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="urlLabel" for="page-url">
                  <Translate contentKey="wataInternalWebApp.page.url">Url</Translate>
                </Label>
                <AvField id="page-url" data-cy="url" type="text" name="url" />
              </AvGroup>
              <AvGroup>
                <Label id="imageLabel" for="page-image">
                  <Translate contentKey="wataInternalWebApp.page.image">Image</Translate>
                </Label>
                <AvField id="page-image" data-cy="image" type="text" name="image" />
              </AvGroup>
              <AvGroup>
                <Label id="createdByLabel" for="page-createdBy">
                  <Translate contentKey="wataInternalWebApp.page.createdBy">Created By</Translate>
                </Label>
                <AvField id="page-createdBy" data-cy="createdBy" type="string" className="form-control" name="createdBy" />
              </AvGroup>
              <AvGroup>
                <Label id="createdDateLabel" for="page-createdDate">
                  <Translate contentKey="wataInternalWebApp.page.createdDate">Created Date</Translate>
                </Label>
                <AvInput
                  id="page-createdDate"
                  data-cy="createdDate"
                  type="datetime-local"
                  className="form-control"
                  name="createdDate"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.pageEntity.createdDate)}
                />
              </AvGroup>
              <AvGroup>
                <Label id="updatedByLabel" for="page-updatedBy">
                  <Translate contentKey="wataInternalWebApp.page.updatedBy">Updated By</Translate>
                </Label>
                <AvField id="page-updatedBy" data-cy="updatedBy" type="string" className="form-control" name="updatedBy" />
              </AvGroup>
              <AvGroup>
                <Label id="updatedDateLabel" for="page-updatedDate">
                  <Translate contentKey="wataInternalWebApp.page.updatedDate">Updated Date</Translate>
                </Label>
                <AvInput
                  id="page-updatedDate"
                  data-cy="updatedDate"
                  type="datetime-local"
                  className="form-control"
                  name="updatedDate"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.pageEntity.updatedDate)}
                />
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/page" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  pageEntity: storeState.page.entity,
  loading: storeState.page.loading,
  updating: storeState.page.updating,
  updateSuccess: storeState.page.updateSuccess,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PageUpdate);
