import {IJobs} from 'app/shared/model/jobs.model';

export interface IApplication {
  id?: number;
  name?: string;
  phoneNumber?: string;
  email?: string;
  attachedDocuments?: string;
  createdDate?: string;
  jobs?: IJobs | null;
}

export const defaultValue: Readonly<IApplication> = {};
