import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { TextFormat, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { toast } from 'react-toastify';

export const ArticleCategoriesDetail = (props: any) => {
  const [dataDetail, setDataDetail] = useState({
    id: null,
    slug: null,
    createdDate: null,
    createdBy: null,
    name: null,
  });

  const apiUrlV2 = 'api/article-categories';

  const getEntity = async (id: any) => {
    const requestUrl = `${apiUrlV2}/${id}`;

    const result = await axios.get<any>(requestUrl);
    toast.success('Success!');
    setDataDetail(result?.data);
  };

  useEffect(() => {
    getEntity(props?.match?.params?.id);
  }, []);

  return (
    <Row>
      <Col md="8">
        <h2 data-cy="articleCategoriesDetailsHeading">
          <Translate contentKey="wataInternalWebApp.articleCategories.detail.title">ArticleCategories</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{dataDetail?.id}</dd>
          <dt>
            <span id="slug">
              <Translate contentKey="wataInternalWebApp.articleCategories.slug">Slug</Translate>
            </span>
          </dt>
          <dd>{dataDetail?.slug}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="wataInternalWebApp.articleCategories.name">Name</Translate>
            </span>
          </dt>
          <dd>{dataDetail?.name}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="wataInternalWebApp.articleCategories.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>{dataDetail?.createdDate ? <TextFormat value={dataDetail.createdDate} type="date" /> : null}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="wataInternalWebApp.articleCategories.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{dataDetail?.createdBy}</dd>
        </dl>
        <Button tag={Link} to="/article-categories" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/article-categories/${dataDetail?.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ArticleCategoriesDetail;
