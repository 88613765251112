import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { toast } from 'react-toastify';

import { EditorState } from 'draft-js';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { stateFromHTML } from 'draft-js-import-html';


export const NewsDetail = (props: any) => {
  const [newsEntity, setNewsEntity] = useState({
    id: null,
    title: null,
	webTitle: null,
	slug: null,
	shortDescription: null,
    description: null,
    image: null,
    thumbnail: null,
    type: null,
    status: null,
    createdBy: null,
    createdDate: null,
    publishedDate: null,
    updatedDate: null,
    updatedBy: null,
    author: null,
    langCode: null,
	parentId: null,
  });

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const apiUrl = 'api/new';

  const getEntity = async (id: any) => {
    const requestUrl = `${apiUrl}/?id=${id}&lang=`;
    const result = await axios.get<any>(requestUrl);
    if (result) {
      toast.success('Success!');
    }
    setNewsEntity(result?.data);
  };

  useEffect(() => {
    getEntity(props?.match?.params?.id);
  }, []);

  useEffect(() => {
    if (newsEntity?.description) {
      const htmlContent = newsEntity?.description;
      const convertedState = stateFromHTML(htmlContent);
      const editorValue = EditorState.createWithContent(convertedState);
      setEditorState(editorValue);
    }
  }, [newsEntity]);


  return (
    <Row>
      <Col md="8">
        <h2 data-cy="newsDetailsHeading">
          <Translate contentKey="wataInternalWebApp.news.detail.title">News</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{newsEntity.id}</dd>
          <dt>
            <span id="title">
              <Translate contentKey="wataInternalWebApp.news.title">Title</Translate>
            </span>
          </dt>
          <dd>{newsEntity.title}</dd>
          <dt>
            <span id="webTitle">
              <Translate contentKey="wataInternalWebApp.news.webTitle">Web Title</Translate>
            </span>
          </dt>
          <dd>{newsEntity.webTitle}</dd>
          <dt>
            <span id="slug">
              <Translate contentKey="wataInternalWebApp.news.slug">Slug</Translate>
            </span>
          </dt>
          <dd>{newsEntity.slug}</dd>
		  <dt>
            <span id="shortDescription">
              <Translate contentKey="wataInternalWebApp.news.shortDescription">shortDescription</Translate>
            </span>
          </dt>
          <dt>
            <span id="description">
              <Translate contentKey="wataInternalWebApp.news.description">Description</Translate>
            </span>
          </dt>
          <dd>{newsEntity.description}</dd>
          <dt>
            <span id="image">
              <Translate contentKey="wataInternalWebApp.news.image">Image</Translate>
            </span>
          </dt>
          <dd>{newsEntity.image}</dd>
          <dt>
            <span id="thumbnail">
              <Translate contentKey="wataInternalWebApp.news.thumbnail">Thumbnail</Translate>
            </span>
          </dt>
          <dd>{newsEntity.thumbnail}</dd>
          <dt>
            <span id="type">
              <Translate contentKey="wataInternalWebApp.news.type">Type</Translate>
            </span>
          </dt>
          <dd>{newsEntity.type}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="wataInternalWebApp.news.status">Status</Translate>
            </span>
          </dt>
          <dd>{newsEntity.status}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="wataInternalWebApp.news.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{newsEntity.createdBy}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="wataInternalWebApp.news.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>{newsEntity.createdDate ? <TextFormat value={newsEntity.createdDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="publishedDate">
              <Translate contentKey="wataInternalWebApp.news.publishedDate">Published Date</Translate>
            </span>
          </dt>
          <dd>{newsEntity.publishedDate ? <TextFormat value={newsEntity.publishedDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="updatedDate">
              <Translate contentKey="wataInternalWebApp.news.updatedDate">Updated Date</Translate>
            </span>
          </dt>
          <dd>{newsEntity.updatedDate ? <TextFormat value={newsEntity.updatedDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="updatedBy">
              <Translate contentKey="wataInternalWebApp.news.updatedBy">Updated By</Translate>
            </span>
          </dt>
          <dd>{newsEntity.updatedBy}</dd>
          <dt>
            <span id="author">
              <Translate contentKey="wataInternalWebApp.news.author">Author</Translate>
            </span>
          </dt>
          <dd>{newsEntity.author}</dd>
          <dt>
            <span id="langCode">
              <Translate contentKey="wataInternalWebApp.news.langCode">Lang Code</Translate>
            </span>
          </dt>
          <dd>{newsEntity.langCode}</dd>
          <dt>
            <span id="parentId">
              <Translate contentKey="wataInternalWebApp.news.parentId">Parent Id</Translate>
            </span>
          </dt>
          <dd>{newsEntity.parentId}</dd>
        </dl>
        <Button tag={Link} to="/news" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/news/${newsEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};


export default NewsDetail;
