import {ISection} from 'app/shared/model/section.model';

export interface IItem {
  id?: number;
  title?: string;
  metaData?: string | null;
  description?: string | null;
  icon?: string | null;
  featureImage?: string | null;
  linkTo?: string | null;
  createdBy?: number | null;
  createdDate?: string | null;
  updatedDate?: string | null;
  updatedBy?: number | null;
  section?: ISection | null;
}

export const defaultValue: Readonly<IItem> = {};
