import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_DATE_FORMAT } from 'app/config/constants';
import axios from 'axios';
import { toast } from 'react-toastify';

import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateFromHTML } from 'draft-js-import-html';

export const WorksDetail = (props: any) => {
  const [dataDetail, setDataDetail] = useState({
    id: null,
    title: null,
    description: null,
    slug: null,
    image: null,
    thumbnail: null,
    excerpt: null,
    content: null,
    status: null,
    createdBy: null,
    createdDate: null,
    publishedDate: null,
    updatedDate: null,
    startDate: null,
    endDate: null,
    updatedBy: null,
    author: null,
    contentAlt: null,
    isTableContent: null,
    titleTag: null,
  });

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const apiUrl = 'api/work';

  const getEntity = async (id: any) => {
    const requestUrl = `${apiUrl}/?id=${id}&lang=`;
    const result = await axios.get<any>(requestUrl);
    if (result) {
      toast.success('Success!');
    }
    setDataDetail(result?.data);
  };

  useEffect(() => {
    getEntity(props?.match?.params?.id);
  }, []);

  useEffect(() => {
    if (dataDetail?.content) {
      const htmlContent = dataDetail?.content;
      const convertedState = stateFromHTML(htmlContent);
      const editorValue = EditorState.createWithContent(convertedState);
      setEditorState(editorValue);
    }
  }, [dataDetail]);

  return (
    <Row>
      <Col md="8">
        <h2 data-cy="worksDetailsHeading">
          <Translate contentKey="wataInternalWebApp.works.detail.title">Works</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{dataDetail?.id}</dd>
          <dt>
            <span id="title">
              <Translate contentKey="wataInternalWebApp.works.title">Title</Translate>
            </span>
          </dt>
          <dd>{dataDetail?.title}</dd>
          <dt>
            <span id="title">
              <Translate contentKey="wataInternalWebApp.works.titleTag">Title Tag</Translate>
            </span>
          </dt>
          <dd>{dataDetail?.titleTag}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="wataInternalWebApp.works.description">Meta Description</Translate>
            </span>
          </dt>
          <dd>{dataDetail?.description}</dd>
          <dt>
            <span id="slug">
              <Translate contentKey="wataInternalWebApp.works.slug">Slug</Translate>
            </span>
          </dt>
          <dd>{dataDetail?.slug}</dd>
          <dt>
            <span id="bannerAlt">
              <Translate contentKey="wataInternalWebApp.works.contentAlt">Content Image Alt</Translate>
            </span>
          </dt>
          <dd>{dataDetail?.contentAlt}</dd>
          <dt>
            <span id="excerpt">
              <Translate contentKey="wataInternalWebApp.works.excerpt">Short Description</Translate>
            </span>
          </dt>
          <dd>{dataDetail?.excerpt}</dd>
          <dt>
            <span id="content">
              <Translate contentKey="wataInternalWebApp.works.content">Content</Translate>
            </span>
          </dt>
          {/* <dd>{dataDetail?.content}</dd> */}
          <Editor editorState={editorState} wrapperClassName="demo-wrapper" editorClassName="demo-editor" readOnly />
          <dt>
            <span id="status">
              <Translate contentKey="wataInternalWebApp.works.status">Status</Translate>
            </span>
          </dt>
          <dd>{dataDetail?.status}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="wataInternalWebApp.works.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{dataDetail?.createdBy}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="wataInternalWebApp.works.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>{dataDetail?.createdDate ? <TextFormat value={dataDetail?.createdDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="publishedDate">
              <Translate contentKey="wataInternalWebApp.works.publishedDate">Published Date</Translate>
            </span>
          </dt>
          <dd>
            {dataDetail?.publishedDate ? <TextFormat value={dataDetail?.publishedDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="updatedDate">
              <Translate contentKey="wataInternalWebApp.works.updatedDate">Updated Date</Translate>
            </span>
          </dt>
          <dd>{dataDetail?.updatedDate ? <TextFormat value={dataDetail?.updatedDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="startDate">
              <Translate contentKey="wataInternalWebApp.works.startDate">Start Date</Translate>
            </span>
          </dt>
          <dd>{dataDetail?.startDate ? <TextFormat value={dataDetail?.startDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="endDate">
              <Translate contentKey="wataInternalWebApp.works.endDate">End Date</Translate>
            </span>
          </dt>
          <dd>{dataDetail?.endDate ? <TextFormat value={dataDetail?.endDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="updatedBy">
              <Translate contentKey="wataInternalWebApp.works.updatedBy">Updated By</Translate>
            </span>
          </dt>
          <dd>{dataDetail?.updatedBy}</dd>
          <dt>
            <Translate contentKey="wataInternalWebApp.works.author">Author</Translate>
          </dt>
          <dd>{dataDetail?.author ? dataDetail?.author.name : ''}</dd>
          <dt>
            <Translate contentKey="wataInternalWebApp.works.isTableContent">Table Content</Translate>
          </dt>
          <dd>{dataDetail?.isTableContent ? 'Yes' : 'No'}</dd>
        </dl>
        <Button tag={Link} to="/works" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/works/${dataDetail?.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default WorksDetail;
