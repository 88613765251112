import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {TextFormat, Translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntity} from './page.reducer';
import {APP_DATE_FORMAT} from 'app/config/constants';

export interface IPageDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const PageDetail = (props: IPageDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const {pageEntity} = props;
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="pageDetailsHeading">
          <Translate contentKey="wataInternalWebApp.page.detail.title">Page</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{pageEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="wataInternalWebApp.page.name">Name</Translate>
            </span>
          </dt>
          <dd>{pageEntity.name}</dd>
          <dt>
            <span id="title">
              <Translate contentKey="wataInternalWebApp.page.title">Title</Translate>
            </span>
          </dt>
          <dd>{pageEntity.title}</dd>
          <dt>
            <span id="slug">
              <Translate contentKey="wataInternalWebApp.page.slug">Slug</Translate>
            </span>
          </dt>
          <dd>{pageEntity.slug}</dd>
          <dt>
            <span id="template">
              <Translate contentKey="wataInternalWebApp.page.template">Template</Translate>
            </span>
          </dt>
          <dd>{pageEntity.template}</dd>
          <dt>
            <span id="featureImage">
              <Translate contentKey="wataInternalWebApp.page.featureImage">Feature Image</Translate>
            </span>
          </dt>
          <dd>{pageEntity.featureImage}</dd>
          <dt>
            <span id="thumbnail">
              <Translate contentKey="wataInternalWebApp.page.thumbnail">Thumbnail</Translate>
            </span>
          </dt>
          <dd>{pageEntity.thumbnail}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="wataInternalWebApp.page.description">Description</Translate>
            </span>
          </dt>
          <dd>{pageEntity.description}</dd>
          <dt>
            <span id="keyword">
              <Translate contentKey="wataInternalWebApp.page.keyword">Keyword</Translate>
            </span>
          </dt>
          <dd>{pageEntity.keyword}</dd>
          <dt>
            <span id="type">
              <Translate contentKey="wataInternalWebApp.page.type">Type</Translate>
            </span>
          </dt>
          <dd>{pageEntity.type}</dd>
          <dt>
            <span id="url">
              <Translate contentKey="wataInternalWebApp.page.url">Url</Translate>
            </span>
          </dt>
          <dd>{pageEntity.url}</dd>
          <dt>
            <span id="image">
              <Translate contentKey="wataInternalWebApp.page.image">Image</Translate>
            </span>
          </dt>
          <dd>{pageEntity.image}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="wataInternalWebApp.page.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{pageEntity.createdBy}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="wataInternalWebApp.page.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>{pageEntity.createdDate ?
            <TextFormat value={pageEntity.createdDate} type="date" format={APP_DATE_FORMAT}/> : null}</dd>
          <dt>
            <span id="updatedBy">
              <Translate contentKey="wataInternalWebApp.page.updatedBy">Updated By</Translate>
            </span>
          </dt>
          <dd>{pageEntity.updatedBy}</dd>
          <dt>
            <span id="updatedDate">
              <Translate contentKey="wataInternalWebApp.page.updatedDate">Updated Date</Translate>
            </span>
          </dt>
          <dd>{pageEntity.updatedDate ?
            <TextFormat value={pageEntity.updatedDate} type="date" format={APP_DATE_FORMAT}/> : null}</dd>
        </dl>
        <Button tag={Link} to="/page" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left"/>{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/page/${pageEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt"/>{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({page}: IRootState) => ({
  pageEntity: page.entity,
});

const mapDispatchToProps = {getEntity};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PageDetail);
