import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {TextFormat, Translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntity} from './item.reducer';
import {APP_DATE_FORMAT} from 'app/config/constants';

export interface IItemDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const ItemDetail = (props: IItemDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const {itemEntity} = props;
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="itemDetailsHeading">
          <Translate contentKey="wataInternalWebApp.item.detail.title">Item</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{itemEntity.id}</dd>
          <dt>
            <span id="title">
              <Translate contentKey="wataInternalWebApp.item.title">Title</Translate>
            </span>
          </dt>
          <dd>{itemEntity.title}</dd>
          <dt>
            <span id="metaData">
              <Translate contentKey="wataInternalWebApp.item.metaData">Meta Data</Translate>
            </span>
          </dt>
          <dd>{itemEntity.metaData}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="wataInternalWebApp.item.description">Description</Translate>
            </span>
          </dt>
          <dd>{itemEntity.description}</dd>
          <dt>
            <span id="icon">
              <Translate contentKey="wataInternalWebApp.item.icon">Icon</Translate>
            </span>
          </dt>
          <dd>{itemEntity.icon}</dd>
          <dt>
            <span id="featureImage">
              <Translate contentKey="wataInternalWebApp.item.featureImage">Feature Image</Translate>
            </span>
          </dt>
          <dd>{itemEntity.featureImage}</dd>
          <dt>
            <span id="linkTo">
              <Translate contentKey="wataInternalWebApp.item.linkTo">Link To</Translate>
            </span>
          </dt>
          <dd>{itemEntity.linkTo}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="wataInternalWebApp.item.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{itemEntity.createdBy}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="wataInternalWebApp.item.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>{itemEntity.createdDate ?
            <TextFormat value={itemEntity.createdDate} type="date" format={APP_DATE_FORMAT}/> : null}</dd>
          <dt>
            <span id="updatedDate">
              <Translate contentKey="wataInternalWebApp.item.updatedDate">Updated Date</Translate>
            </span>
          </dt>
          <dd>{itemEntity.updatedDate ?
            <TextFormat value={itemEntity.updatedDate} type="date" format={APP_DATE_FORMAT}/> : null}</dd>
          <dt>
            <span id="updatedBy">
              <Translate contentKey="wataInternalWebApp.item.updatedBy">Updated By</Translate>
            </span>
          </dt>
          <dd>{itemEntity.updatedBy}</dd>
          <dt>
            <Translate contentKey="wataInternalWebApp.item.section">Section</Translate>
          </dt>
          <dd>{itemEntity.section ? itemEntity.section.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/item" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left"/>{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/item/${itemEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt"/>{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({item}: IRootState) => ({
  itemEntity: item.entity,
});

const mapDispatchToProps = {getEntity};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ItemDetail);
